import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import style from './booking.css';

const styles = (theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[600],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


export default function CustomizedDialogs({ itemList, changeStatus, classes, }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} >
                MORE
            </Button>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={"lg"} maxWidth={"md"}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Booking Details
                </DialogTitle>
                <p className={style} > Hotel Name : {itemList.hotelId?.name} </p>
                <p className={style} > Booking Type : {itemList.bookingType} </p>
                <p className={style} > Type Id : {itemList.typeId} </p>
                <p className={style} > Name : {itemList.name} </p>
                <p className={style} > Price : {itemList.price} </p>
                <p className={style} > Discount : {itemList.discount}% </p>
                <p className={style} > Checking Date : {itemList.checkingDate} </p>
                <p className={style} > CheckoutDate : {itemList.checkoutDate} </p>
                <p className={style} > Adults : {itemList.adults} </p>
                <p className={style} > Childrens : {itemList.childrens} </p>
                <p className={style} > Number Of Rooms : {itemList.numberOfRooms} </p>
                <p className={style} > User Name : {itemList.userName} </p>
                <p className={style} > User Email : {itemList.userEmail} </p>
                <p className={style} > Contact Number : {itemList.contactNumber} </p>
                {itemList.status === "active" ? (
                    <Button 
                        variant="contained"
                        className={classes.red}
                        style={{width:"115px", marginLeft: "4%" }}
                        onClick={() => changeStatus(itemList._id, "inactive")}
                    >
                        reject
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        className={classes.green}
                        style={{width:"100px", marginLeft: "4%" }}
                        onClick={() => changeStatus(itemList._id, "active")}
                    >
                        approve
                    </Button>
                )}
            </Dialog>
        </div>
    );
}