import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Minimum length for the name is 3!")
    .max(30, "Maximum length for the vehicle Rto is 30!"),
  description: Yup.string()
    .required("Description is required")
    .min(3, "Minimum length for the description is 3!")
    .max(300, "Maximum length for the vehicle Rto is 300!"),
  image: Yup.string().min(5, "Minimum length for the image url is 5!"),
  serviceType: Yup.string()
    .required("service Type is required")
    .min(3, "Minimum length for the service type url is 3!"),
  discount: Yup.string(),
  price: Yup.string().required("Price is required"),
});
