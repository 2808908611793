import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  youTubelink1: Yup.string()
    .required("Link 1 is required")
    .min(1, "Minimum length for the link is 1!")
    .max(150, "Maximum length for the link is 150!"),
  youTubelink2: Yup.string()
    .required("Link 2 is required")
    .min(1, "Minimum length for the link is 1!")
    .max(150, "Maximum length for the link is 150!"),
  youTubelink3: Yup.string()
    .required("Link 3 is required")
    .min(1, "Minimum length for the link is 1!")
    .max(150, "Maximum length for the link is 150!"),
  youTubelink4: Yup.string()
    .required("Link 4 is required")
    .min(1, "Minimum length for the link is 1!")
    .max(150, "Maximum length for the link is 150!"),
  youTubelink5: Yup.string()
    .required("Link 5 is required")
    .min(1, "Minimum length for the link is 1!")
    .max(150, "Maximum length for the link is 150!"),
  youTubelink6: Yup.string()
    .required("Link 6 is required")
    .min(1, "Minimum length for the link is 1!")
    .max(150, "Maximum length for the link is 150!"),
});
