import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "libs/axios";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../utils/styles/styles";
import Button from "../../../components/UI/ReusableButton";
import CircularProgress from "../../../components/UI/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { TextField as TF } from "@material-ui/core";
import { validationSchema } from "../../../utils/validations/admin/hotelAdminValidation";
import { uptateValidationSchema } from "../../../utils/validations/admin/hotelAdminUpdateValidation";

const CreateHotelAdmin = (props) => {
  const classes = useStyles();
  const { state } = useLocation();
  let navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    userName: "",
    role: "hoteladmin",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    itemSelect: "",
    district: "",
  });
  const [componentType, setComponentType] = useState("create");
  const [pickUpdate, setPickUpdate] = useState(true);
  const [selectItem, setSelectItem] = useState([]);
  const [sltitem, setSltitem] = useState("");
  const [dis, setdis] = useState("");
  const district = [
    "Colombo",
    "Gampaha",
    "Kalutara",
    "Kandy",
    "Matale",
    "Nuwara-Eliya",
    "Galle",
    "Matara",
    "Hambantota",
    "Jaffna",
    "Kilinochchi",
    "Mannar",
    "Vavuniya",
    "Mullaitivu",
    "Batticaloa",
    "Ampara",
    "Trincomalee",
    "Kurunegala",
    "Puttalam",
    "Anuradhapura",
    "Polonnaruwa",
    "Badulla",
    "Moneragala",
    "Ratnapura",
    "Kegalle",
  ];

  const [callingBackend, setCallingBackend] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const itemLoad = async () => {
    try {
      const { data } = await axios.get(`/item/`);
      setSelectItem(data);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Item data load is faild!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Item data load is faild!",
          }),
        5000
      );
    }
  };

  const submit = async (e, { resetForm }) => {
    if (componentType === "create") {
      try {
        setCallingBackend(true);
        await axios.post("/user/hotel-user", {
          userName: e.userName,
          role: e.role,
          email: e.email,
          password: e.password,
          phoneNumber: e.phoneNumber,
          itemSelect: sltitem,
          district: dis,
        });
        setdis("");
        setSltitem("");
        resetForm();
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Hotel admin created successfully!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Hotel admin created successfully!",
            }),
          5000
        );
      } catch (error) {
        if (error.response.data === "Hotel admin already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Hotel admin already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Hotel admin already exists!",
              }),
            5000
          );
        } else if (error.response.status === 401) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Unauthorized Access!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Unauthorized Access!",
              }),
            5000
          );
        } else if (error.response.status === 500) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Server Error!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Server Error!",
              }),
            5000
          );
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Hotel admin already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Hotel admin already exists!",
              }),
            5000
          );
        }
      } finally {
        setCallingBackend(false);
      }
    } else {
      try {
        setCallingBackend(true);
        await axios.put(`/user/hotel-update/${state.id}`, {
          userName: e.userName,
          role: e.role,
          email: e.email,
          password: e.password,
          phoneNumber: e.phoneNumber,
          itemSelect: sltitem,
          district: dis,
        });
        navigate("/view-hotel-admins");
      } catch (error) {
        if (error.response.data === "Hotel admin already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Hotel admin already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Hotel admin already exists!",
              }),
            5000
          );
        } else if (error.response.status === 401) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Unauthorized Access!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Unauthorized Access!",
              }),
            5000
          );
        } else if (error.response.status === 500) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Server Error!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Server Error!",
              }),
            5000
          );
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Hotel admin already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Hotel admin already exists!",
              }),
            5000
          );
        }
      } finally {
        setCallingBackend(false);
      }
    }
  };

  const get = async (id) => {
    try {
      const { data } = await axios.get(`/user/hotel-admin/${id}`);

      setInitialValues({
        userName: data.userName,
        role: data.role,
        email: data.email,
        phoneNumber: data.phoneNumber,
        password: data.password,
        confirmPassword: data.password,
        itemSelect: data.itemSelect,
        district: data.district,
      });
      setSltitem(data.itemSelect);
      setdis(data.district);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Data loading failed!",
          }),
        5000
      );
    }
  };

  useEffect(() => {
    itemLoad();
  }, []);

  useEffect(() => {
    if (state && state?.id) {
      setComponentType("update");
      get(state.id);
    }
    setTimeout(() => setIsLoading(false), 1000);
    // eslint-disable-next-line
  }, [state?.id]);

  const name = selectItem.find((i) => i._id === sltitem);

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={
            componentType === "create"
              ? validationSchema
              : uptateValidationSchema
          }
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Card variant="outlined">
                  <CardHeader
                    title={
                      componentType === "create"
                        ? "Create a Hotel Admin"
                        : "Update the Hotel Admin"
                    }
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="userName"
                          label="User Name"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="role"
                          label="Role"
                          value="Hotel Admin"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="email"
                          label="Email"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      {componentType === "create" && (
                        <Grid item xs={12} sm={12} md={6}>
                          <Field
                            name="password"
                            label="Password"
                            component={TextField}
                            variant="outlined"
                            fullWidth
                            required
                            multiline
                          />
                        </Grid>
                      )}

                      {componentType === "create" && (
                        <Grid item xs={12} sm={12} md={6}>
                          <Field
                            name="confirmPassword"
                            label="Confirm Password"
                            component={TextField}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="phoneNumber"
                          label="Phone Number"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Autocomplete
                          value={{ name: name?.name }}
                          options={selectItem} //array
                          name="itemSelect"
                          onChange={(event, newValue) => {
                            setSltitem(newValue._id);
                          }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TF
                              {...params}
                              label="Select Item "
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Autocomplete
                          value={dis}
                          options={district}
                          name="district"
                          onChange={(event, newValue) => {
                            setdis(newValue);
                            setPickUpdate(false);
                            // handleClick(req, newValue);
                            // setReq(newValue);
                          }}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TF
                              {...params}
                              label="District "
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardActions>
                    {callingBackend ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.button}
                        color={
                          componentType === "create" ? "primary" : "secondary"
                        }
                        type="submit"
                        disabled={
                          (pickUpdate && !dirty) || !isValid || !dis || !sltitem
                        }
                      >
                        {componentType === "create" ? "create" : "update"}
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {alert.showAlert && (
        <Grid item md={12}>
          <Alert
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, showAlert: false })}
          >
            {alert.message}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default CreateHotelAdmin;
