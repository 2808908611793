import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography,
} from "@material-ui/core";
import axios from "libs/axios";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../utils/styles/styles";
import Button from "../../../components/UI/ReusableButton";
import CircularProgress from "../../../components/UI/CircularProgress";
import ColouPicker from "components/common/ColouPicker";

export default function SearchBarStyle() {
  const classes = useStyles();

  const [searchBaractiveButtonColor, setSearchBaractiveButtonColor] =
    useState("");
  const [searchBaractiveTextColor, setSearchBaractiveTextColor] =
    useState("");
  const [searchBardefaultButtonColor, setSearchBardefaultButtonColor] =
    useState("");
  const [searchBardefaultTextColor, setSearchBardefaultTextColor] =
    useState("");

  const [callingBackend, setCallingBackend] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async () => {
    try {
      setCallingBackend(true);
      await axios.put(`/property/searchbar-style-update`, {
        searchBaractiveButtonColor: searchBaractiveButtonColor,
        searchBaractiveTextColor: searchBaractiveTextColor,
        searchBardefaultButtonColor: searchBardefaultButtonColor,
        searchBardefaultTextColor: searchBardefaultTextColor,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Search Bar Style successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Search Bar Style successfully!",
          }),
        5000
      );
    } catch (error) {
      if (error.response.data === "Search Bar Style already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Search Bar Style already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Search Bar Style already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Search Bar Style already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Search Bar Style already exists!",
            }),
          5000
        );
      }
    } finally {
      setCallingBackend(false);
    }
  };

  const get = async (id) => {
    try {
      const { data } = await axios.get(`/item/update-data`);
      setSearchBaractiveButtonColor(data.searchBaractiveButtonColor);
      setSearchBaractiveTextColor(data.searchBaractiveTextColor);
      setSearchBardefaultButtonColor(data.searchBardefaultButtonColor);
      setSearchBardefaultTextColor(data.searchBardefaultTextColor);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Data loading failed!",
          }),
        5000
      );
    }
  };
  useEffect(() => {
    get();
  }, []);

  return (
    <div>
      <Grid container className={classes.root} spacing={1}>
        <Grid fullWidth item xs={12} sm={12} md={12}>
          <Card variant="outlined">
            <CardHeader title="Search Bar Styles " />
            <CardContent>
              <Grid container spacing={2}>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography>Active Button Color: </Typography>
                  <ColouPicker
                    color={searchBaractiveButtonColor}
                    setColor={setSearchBaractiveButtonColor}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography>Active Button Text Color: </Typography>
                  <ColouPicker
                    color={searchBaractiveTextColor}
                    setColor={setSearchBaractiveTextColor}
                  />
                </Grid>{" "}
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography>Default Button Color: </Typography>
                  <ColouPicker
                    color={searchBardefaultButtonColor}
                    setColor={setSearchBardefaultButtonColor}
                  />
                </Grid>{" "}
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography>Default Button Text Color: </Typography>
                  <ColouPicker
                    color={searchBardefaultTextColor}
                    setColor={setSearchBardefaultTextColor}
                  />
                </Grid>{" "}
              </Grid>
            </CardContent>

            <CardActions>
              {callingBackend ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  className={classes.button}
                  color="primary"
                  onClick={() => {
                    submit();
                  }}
                >
                  Save
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
        {alert.showAlert && (
          <Grid item md={12}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
