import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Minimum length for the name is 3!")
    .max(50, "Maximum length for the name is 50!"),
  discount: Yup.string().required("Discount is required"),
  perPerson: Yup.string(),
  memberCount: Yup.string(),
  startDate: Yup.string().required("Start date is required"),
  endDate: Yup.string().required("End date is required"),
  price: Yup.string().required("Price is required"),
  description: Yup.string()
    .required("Description is required")
    .min(3, "Minimum length for the description is 3!")
    .max(300, "Maximum length for the vehicle Rto is 300!"),
  image1: Yup.string(),
  image2: Yup.string(),
  image3: Yup.string(),
  image4: Yup.string(),
  image5: Yup.string(),
});
