import React, { Fragment } from "react";
import { TableCell } from "@material-ui/core";
import ReusableTable from "../../../src/components/layouts/ReusableTable";
import SimpleDialog from "../../../src/components/layouts/SimpleDialog";
import Bookigpopup from "../Booking/Bookigpopup";

const headers = [
  {
    text: "Name",
    value: "name",
  },
  {
    text: "User Name",
    value: "userName",
  },
  {
    text: "Number Of Rooms",
    value: "numberOfRooms",
  },
  {
    text: "Price",
    value: "price",
  },
  {
    text: "Contact Number",
    value: "contactNumber",
  },
  {
    text: "More Details",
    value: "itemList",
  },
];

export default function ViewMeta(props) {
  const [open, setOpen] = React.useState(false);

  // eslint-disable-next-line
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleClose = (value) => {
    setOpen(false);
  };

  const TableB = ({
    item,
    changeStatus,
    classes,
  }) => {
    return (
      <Fragment>
        <TableCell key="name">{item.name}</TableCell>
        <TableCell key="userName">{item.userName}</TableCell>
        <TableCell key="numberOfRooms">{item.numberOfRooms}</TableCell>
        <TableCell key="price">{item.price}</TableCell>
        <TableCell key="contactNumber">{item.contactNumber}</TableCell>
        <TableCell key="itemList">
          <Bookigpopup itemList={item} changeStatus={changeStatus} classes={classes}/>
        </TableCell>
        <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title="View Booking"
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl="/booking/"
      statusChangeUrl="/booking/status/"
      onboardChangeUrl="/firstCustomers/onboard/"
      deleteItemPath="/firstCustomers/"
      {...props}
    />
  );
}
