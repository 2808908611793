import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "libs/axios";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../utils/styles/styles";
import Button from "../../../components/UI/ReusableButton";
import CircularProgress from "../../../components/UI/CircularProgress";

import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { validationSchema } from "../../../utils/validations/settings/testimonialValidation";

const CreateTestimonial = (props) => {
  const classes = useStyles();
  const { state } = useLocation();
  let navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    name: "",
    country: "",
    description: "",
    heading:"",
  });
  const [componentType, setComponentType] = useState("create");
  
  const [callingBackend, setCallingBackend] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e, { resetForm }) => {
    if (componentType === "create") {
      try {
        setCallingBackend(true);
        await axios.post("/testimonial/", {
          name: e.name,
          country: e.country,
          heading: e.heading,
          description: e.description,
        });
        resetForm();
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Testimonial created successfully!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Testimonial created successfully!",
            }),
          5000
        );
      } catch (error) {
        if (error.response.data === "Testimonial already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Testimonial already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Testimonial already exists!",
              }),
            5000
          );
        } else if (error.response.status === 401) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Unauthorized Access!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Unauthorized Access!",
              }),
            5000
          );
        } else if (error.response.status === 500) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Server Error!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Server Error!",
              }),
            5000
          );
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Testimonial already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Testimonial already exists!",
              }),
            5000
          );
        }
      } finally {
        setCallingBackend(false);
      }
    } else {
      try {
        setCallingBackend(true);
        await axios.put(`/testimonial/${state.id}`, {
          name: e.name,
          country: e.country,
          heading: e.heading,
          description: e.description,
        });
        navigate("/view-testimonial");
      } catch (error) {
        if (error.response.data === "Testimonial already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Testimonial already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Testimonial already exists!",
              }),
            5000
          );
        } else if (error.response.status === 401) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Unauthorized Access!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Unauthorized Access!",
              }),
            5000
          );
        } else if (error.response.status === 500) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Server Error!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Server Error!",
              }),
            5000
          );
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Testimonial already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Testimonial already exists!",
              }),
            5000
          );
        }
      } finally {
        setCallingBackend(false);
      }
    }
  };
  useEffect(() => {
    if (state && state?.id) {
      setComponentType("update");
      setInitialValues({
        name: state.item.name,
        country: state.item.country,
        heading: state.item.heading,
        description: state.item.description,
      });
    }
    setTimeout(() => setIsLoading(false), 1000);
    // eslint-disable-next-line
  }, [state?.id]);

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Card variant="outlined">
                  <CardHeader
                    title={
                      componentType === "create"
                        ? "Create a Testimonial"
                        : "Update the Testimonial"
                    }
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="name"
                          label="Name"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="heading"
                          label="Heading"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="country"
                          label="Country"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="description"
                          label="Description"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardActions>
                    {callingBackend ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.button}
                        color={
                          componentType === "create" ? "primary" : "secondary"
                        }
                        type="submit"
                        disabled={!dirty || !isValid}
                      >
                        {componentType === "create" ? "create" : "update"}
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {alert.showAlert && (
        <Grid item md={12}>
          <Alert
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, showAlert: false })}
          >
            {alert.message}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default CreateTestimonial;
