import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog({rejectBilling, itemList}) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{ backgroundColor: "red", color: "white" }}
      >
        REJECT
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">REJECT</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure wan't to reject this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {rejectBilling(itemList?.id)}}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
