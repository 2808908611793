import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Box,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import axios from "libs/axios";
import storage from "../../../firebase/firebase";

import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../utils/styles/styles";
import Button from "../../../components/UI/ReusableButton";
import CircularProgress from "../../../components/UI/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { validationSchema } from "../../../utils/validations/admin/UpdateChooesusValidation";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const UpdateChooesus = (props) => {
  const classes = useStyles();
  const { state } = useLocation();
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);
  const [initialValues, setInitialValues] = useState({
    title1: "",
    title2: "",
    title3: "",
    title4: "",
    description1: "",
    description2: "",
    description3: "",
    description4: "",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
  });
  const [componentType, setComponentType] = useState("create");
    const [percent, setPercent] = useState(0);
    const [progbar, setprogbar] = useState(false);
    const [defaultImageUpload, setDefaultImageUpload] = useState(false);

  const [dis, setdis] = useState("");

  const [callingBackend, setCallingBackend] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e, { resetForm }) => {
    try {
      setCallingBackend(true);
      await axios.put(`/item/chooseUs-update/`, {
        title1: e.title1,
        title2: e.title2,
        title3: e.title3,
        title4: e.title4,
        description1: e.description1,
        description2: e.description2,
        description3: e.description3,
        description4: e.description4,
        image: e.image,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Profile updated successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Profile updated successfully!",
          }),
        5000
      );
    } catch (error) {
      if (error.response.data === "Profile updated already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Profile updated already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Profile updated already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Profile updated already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Profile updated already exists!",
            }),
          5000
        );
      }
    } finally {
      setCallingBackend(false);
    }
  };

  const get = async () => {
    try {
      const { data } = await axios.get(`/item/update-data`);
      console.log("data", data);
      setInitialValues({
        title1: data?.chooseUs?.title1,
        title2: data?.chooseUs?.title2,
        title3: data?.chooseUs?.title3,
        title4: data?.chooseUs?.title4,
        description1: data?.chooseUs?.description1,
        description2: data?.chooseUs?.description2,
        description3: data?.chooseUs?.description3,
        description4: data?.chooseUs?.description4,
        image: data?.chooseUs?.image,
      });
      setdis(data.district);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Data loading failed!",
          }),
        5000
      );
    }
  };
  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (state && state?.id) {
      setComponentType("update");
      get();
    }
    setTimeout(() => setIsLoading(false), 1000);
    // eslint-disable-next-line
  }, [state?.id]);

  const handleImageAsFile = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue("image", url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };

  const removeImage = (setFieldValue) => {
    setFieldValue(
      "image",
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
    );
  };
  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Card variant="outlined">
                  <CardHeader title="Update the Chooes us" />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="title1"
                          label="title1"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="description1"
                          label="description1"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="title2"
                          label="title2"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="description2"
                          label="description2"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="title3"
                          label="title3"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="description3"
                          label="description3"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="title4"
                          label="title4"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="description4"
                          label="description4"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      
                      <Grid item xs={12} sm={6} md={6}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo">
                                <input
                                  style={{ display: "none" }}
                                  id="upload-photo"
                                  name="upload-photo"
                                  type="file"
                                  accept="image/jpeg,image/png"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile(
                                      e,
                                      setFieldValue,
                                      "image",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.image && (
                                <img
                                  src={values.image}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.image !== "" &&
                                values.image !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardActions>
                    {callingBackend ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.button}
                        color={
                          componentType === "create" ? "primary" : "secondary"
                        }
                        type="submit"
                        disabled={!dirty || !isValid || !dis}
                      >
                        save Changes
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {alert.showAlert && (
        <Grid item md={12}>
          <Alert
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, showAlert: false })}
          >
            {alert.message}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default UpdateChooesus;
