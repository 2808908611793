import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Box,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import axios from "libs/axios";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../utils/styles/styles";
import Button from "../../../components/UI/ReusableButton";
import CircularProgress from "../../../components/UI/CircularProgress";
import storage from "../../../firebase/firebase";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { validationSchema } from "../../../utils/validations/admin/welcomeTextValidation";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const UpdateWelcomeText = (props) => {
  const classes = useStyles();
  const { state } = useLocation();

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);
  
  const [initialValues, setInitialValues] = useState({
    welcometext: "",
    welcomedescription: "",
    welcomeimage1:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    welcomeimage2:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    welcomeimage3:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    welcomeimage4:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    welcomeimage5:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
  });

  const [callingBackend, setCallingBackend] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);
  const [percent, setPercent] = useState(0);
  const [progbar, setprogbar] = useState(false);
  const [defaultImageUpload, setDefaultImageUpload] = useState(false);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e, { resetForm }) => {
    try {
      console.log("update");
      setCallingBackend(true);
      await axios.put(`/item/welcome-text-update`, {
        welcometext: e.welcometext,
        welcomedescription: e.welcomedescription,
        welcomeimage1: e.welcomeimage1,
        welcomeimage2: e.welcomeimage2,
        welcomeimage3: e.welcomeimage3,
        welcomeimage4: e.welcomeimage4,
        welcomeimage5: e.welcomeimage5,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Welcome Text Update successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Welcome Text Update successfully!",
          }),
        5000
      );
    } catch (error) {
      if (error.response.data === "Welcome Text Update already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Welcome Text Update already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Welcome Text Update already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Welcome Text Update already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Welcome Text Update already exists!",
            }),
          5000
        );
      }
    } finally {
      setCallingBackend(false);
    }
  };

  const get = async (id) => {
    try {
      const { data } = await axios.get(`/item/update-data`);
      setInitialValues({
        welcometext: data.welcometext,
        welcomedescription: data.welcomedescription,
        welcomeimage1: data.welcomeimage1,
        welcomeimage2: data.welcomeimage2,
        welcomeimage3: data.welcomeimage3,
        welcomeimage4: data.welcomeimage4,
        welcomeimage5: data.welcomeimage5,
      });
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Data loading failed!",
          }),
        5000
      );
    }
  };
  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (state && state?.id) {
      get();
    }
    setTimeout(() => setIsLoading(false), 1000);
    // eslint-disable-next-line
  }, [state?.id]);

      const handleImageAsFile1 = async (e, setFieldValue, type, values) => {
        try {
          setDefaultImageUpload(true);
          const image = e.target.files[0];
          const storageRef = ref(
            storage,
            `/files/${image.name}${image.lastModifiedDate}`
          );
          const uploadTask = uploadBytesResumable(storageRef, image);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setPercent(percent);
              if (percent === 100) {
                setTimeout(() => {
                  setprogbar(false);
                }, 3000);
              } else if (1 < percent < 99) {
                setprogbar(true);
              } else {
                setprogbar(false);
              }
            },
            (err) => console.log(err),
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                setFieldValue("welcomeimage1", url);
              });
            }
          );
        } catch (error) {
          console.error(error);
        } finally {
          setDefaultImageUpload(false);
        }
      };
      const handleImageAsFile2 = async (e, setFieldValue, type, values) => {
        try {
          setDefaultImageUpload(true);
          const image = e.target.files[0];
          const storageRef = ref(
            storage,
            `/files/${image.name}${image.lastModifiedDate}`
          );
          const uploadTask = uploadBytesResumable(storageRef, image);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setPercent(percent);
              if (percent === 100) {
                setTimeout(() => {
                  setprogbar(false);
                }, 3000);
              } else if (1 < percent < 99) {
                setprogbar(true);
              } else {
                setprogbar(false);
              }
            },
            (err) => console.log(err),
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                setFieldValue("welcomeimage2", url);
              });
            }
          );
        } catch (error) {
          console.error(error);
        } finally {
          setDefaultImageUpload(false);
        }
      };
      const handleImageAsFile3 = async (e, setFieldValue, type, values) => {
        try {
          setDefaultImageUpload(true);
          const image = e.target.files[0];
          const storageRef = ref(
            storage,
            `/files/${image.name}${image.lastModifiedDate}`
          );
          const uploadTask = uploadBytesResumable(storageRef, image);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setPercent(percent);
              if (percent === 100) {
                setTimeout(() => {
                  setprogbar(false);
                }, 3000);
              } else if (1 < percent < 99) {
                setprogbar(true);
              } else {
                setprogbar(false);
              }
            },
            (err) => console.log(err),
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                setFieldValue("welcomeimage3", url);
              });
            }
          );
        } catch (error) {
          console.error(error);
        } finally {
          setDefaultImageUpload(false);
        }
      };
      const handleImageAsFile4 = async (e, setFieldValue, type, values) => {
        try {
          setDefaultImageUpload(true);
          const image = e.target.files[0];
          const storageRef = ref(
            storage,
            `/files/${image.name}${image.lastModifiedDate}`
          );
          const uploadTask = uploadBytesResumable(storageRef, image);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setPercent(percent);
              if (percent === 100) {
                setTimeout(() => {
                  setprogbar(false);
                }, 3000);
              } else if (1 < percent < 99) {
                setprogbar(true);
              } else {
                setprogbar(false);
              }
            },
            (err) => console.log(err),
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                setFieldValue("welcomeimage4", url);
              });
            }
          );
        } catch (error) {
          console.error(error);
        } finally {
          setDefaultImageUpload(false);
        }
      };
      const handleImageAsFile5 = async (e, setFieldValue, type, values) => {
        try {
          setDefaultImageUpload(true);
          const image = e.target.files[0];
          const storageRef = ref(
            storage,
            `/files/${image.name}${image.lastModifiedDate}`
          );
          const uploadTask = uploadBytesResumable(storageRef, image);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setPercent(percent);
              if (percent === 100) {
                setTimeout(() => {
                  setprogbar(false);
                }, 3000);
              } else if (1 < percent < 99) {
                setprogbar(true);
              } else {
                setprogbar(false);
              }
            },
            (err) => console.log(err),
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                setFieldValue("welcomeimage5", url);
              });
            }
          );
        } catch (error) {
          console.error(error);
        } finally {
          setDefaultImageUpload(false);
        }
      };

      const removeImage1 = (setFieldValue) => {
        setFieldValue(
          "welcomeimage1",
          "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
        );
      };
      const removeImage2 = (setFieldValue) => {
        setFieldValue(
          "welcomeimage2",
          "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
        );
      };
      const removeImage3 = (setFieldValue) => {
        setFieldValue(
          "welcomeimage3",
          "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
        );
      };
      const removeImage4 = (setFieldValue) => {
        setFieldValue(
          "welcomeimage4",
          "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
        );
      };
      const removeImage5 = (setFieldValue) => {
        setFieldValue(
          "welcomeimage5",
          "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
        );
      };

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Card variant="outlined">
                  <CardHeader title="Carousel Images & Welcome Text" />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="welcometext"
                          label="Welcome Text"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="welcomedescription"
                          label="Description"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                          rows={4}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo1">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo1"
                                  name="upload-photo1"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile1(
                                      e,
                                      setFieldValue,
                                      "welcomeimage1",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 1 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.welcomeimage1 && (
                                <img
                                  src={values.welcomeimage1}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.welcomeimage1 !== "" &&
                              values.welcomeimage1 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage1(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo2">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo2"
                                  name="upload-photo2"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile2(
                                      e,
                                      setFieldValue,
                                      "welcomeimage2",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 2 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.welcomeimage2 && (
                                <img
                                  src={values.welcomeimage2}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.welcomeimage2 !== "" &&
                              values.welcomeimage2 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage2(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo3">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo3"
                                  name="upload-photo3"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile3(
                                      e,
                                      setFieldValue,
                                      "welcomeimage3",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 3 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.welcomeimage3 && (
                                <img
                                  src={values.welcomeimage3}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.welcomeimage3 !== "" &&
                              values.welcomeimage3 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage3(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo4">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo4"
                                  name="upload-photo4"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile4(
                                      e,
                                      setFieldValue,
                                      "welcomeimage4",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 4 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.welcomeimage4 && (
                                <img
                                  src={values.welcomeimage4}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.welcomeimage4 !== "" &&
                              values.welcomeimage4 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage4(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo5">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo5"
                                  name="upload-photo5"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile5(
                                      e,
                                      setFieldValue,
                                      "welcomeimage5",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 5 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.welcomeimage5 && (
                                <img
                                  src={values.welcomeimage5}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.welcomeimage5 !== "" &&
                              values.welcomeimage5 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage5(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardActions>
                    {callingBackend ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        type="submit"
                        disabled={!dirty || !isValid}
                      >
                        Save
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {alert.showAlert && (
        <Grid item md={12}>
          <Alert
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, showAlert: false })}
          >
            {alert.message}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default UpdateWelcomeText;
