import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography,
} from "@material-ui/core";
import axios from "libs/axios";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../utils/styles/styles";
import Button from "../../../components/UI/ReusableButton";
import CircularProgress from "../../../components/UI/CircularProgress";
import ColouPicker from "components/common/ColouPicker";

export default function ButtonStyle() {

   const classes = useStyles();

  const [buttonTextColor, setButtonTextColor] = useState("");
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState("");

  const [callingBackend, setCallingBackend] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async () => {
    try {
      setCallingBackend(true);
      console.log("test");
      await axios.put(`/property/button-style-update`, {
        buttonTextColor: buttonTextColor,
        buttonBackgroundColor: buttonBackgroundColor,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Button Style successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Button Style successfully!",
          }),
        5000
      );
    } catch (error) {
      if (error.response.data === "Button Style already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Button Style already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Button Style already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Button Style already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Button Style already exists!",
            }),
          5000
        );
      }
    } finally {
      setCallingBackend(false);
    }
  };

  const get = async (id) => {
    try {
      const { data } = await axios.get(`/item/update-data`);
      setButtonTextColor(data.buttonTextColor);
      setButtonBackgroundColor(data.buttonBackgroundColor);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Data loading failed!",
          }),
        5000
      );
    }
  };
  useEffect(() => {
    get();
  }, []);
  return (
    <div>
      <Grid container className={classes.root} spacing={1}>
        <Grid fullWidth item xs={12} sm={12} md={12}>
          <Card variant="outlined">
            <CardHeader title="Button Styles " />
            <CardContent>
              <Grid container spacing={2}>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography>Text Color: </Typography>
                  <ColouPicker
                    color={buttonTextColor}
                    setColor={setButtonTextColor}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography>Background Color: </Typography>
                  <ColouPicker
                    color={buttonBackgroundColor}
                    setColor={setButtonBackgroundColor}
                  />
                </Grid>{" "}
              </Grid>
            </CardContent>

            <CardActions>
              {callingBackend ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  className={classes.button}
                  color="primary"
                  onClick={() => {
                    submit();
                  }}
                >
                  Save
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
        {alert.showAlert && (
          <Grid item md={12}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        )}
      </Grid>
    </div>  )
}
