import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "libs/axios";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../utils/styles/styles";
import Button from "../../../components/UI/ReusableButton";
import CircularProgress from "../../../components/UI/CircularProgress";
// validation
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { validationSchema } from "../../../utils/validations/jobs/firstCustomersValidation";

const CreateFirstCustomers = (props) => {
  const classes = useStyles();
  const { state } = useLocation();
  let navigate = useNavigate();

  // data
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    contactNumber: "",
    numberOFRooms: "",
    hotelAddress: "",
    referalLink: "",
    description: "",
  });

  const [componentType, setComponentType] = useState("create");
  const [callingBackend, setCallingBackend] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  // create method
  const submit = async (e, { resetForm }) => {
    if (componentType === "create") {
      try {
        setCallingBackend(true);
        await axios.post("/firstCustomers/", e);
        resetForm();
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Customer created successfully!",
        });
        setTimeout(() => setAlert({
          showAlert: false,
          severity: 'success',
          message: 'Customer created successfully!',
        }), 5000);
      } catch (error) {
        if (error.response.data === "Customer already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Customer already exists!",
          });
          setTimeout(() => setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Customer already exists!',
          }), 5000);
        } else if (error.response.status === 401) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Unauthorized Access!",
          });
          setTimeout(() => setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Unauthorized Access!',
          }), 5000);
        } else if (error.response.status === 500) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Server Error!",
          });
          setTimeout(() => setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Server Error!',
          }), 5000);
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Something went wrong!",
          });
          setTimeout(() => setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Something went wrong!',
          }), 5000);
        }
      } finally {
        setCallingBackend(false);
      }
    } else {
      try {
        setCallingBackend(true);
        await axios.put(`/firstCustomers/${state.id}`, e);
        navigate("/view-First-Customers");
      } catch (error) {
        if (error.response.data === "Customers already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Customers already exists!",
          });
          setTimeout(() => setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Customers already exists!',
          }), 5000);
        } else if (error.response.status === 401) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Unauthorized Access!",
          });
          setTimeout(() => setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Unauthorized Access!',
          }), 5000);
        } else if (error.response.status === 500) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Server Error!",
          });
          setTimeout(() => setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Server Error!',
          }), 5000);
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Something went wrong!",
          });
          setTimeout(() => setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Something went wrong!',
          }), 5000);
        }
      } finally {
        setCallingBackend(false);
      }
    }
  };

  // get hotel
  const get = async (id) => {
    try {
      const { data } = await axios.get(`/firstCustomers/${id}`);

      setInitialValues({
        name: data.name,
        email: data.email,
        contactNumber: data.contactNumber,
        numberOFRooms: data.numberOFRooms,
        hotelAddress: data.hotelAddress,
        referalLink: data.referalLink,
        description: data.description,
      });
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(() => setAlert({
        showAlert: false,
        severity: 'success',
        message: 'Data loading failed!',
      }), 5000);
    }
  };

  //use effect hook
  useEffect(() => {
    if (state && state?.id) {
      setComponentType("update");
      get(state.id);
    }
    setTimeout(() => setIsLoading(false), 1000);
    // eslint-disable-next-line
  }, [state?.id]);

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty }) => {
            return (
              <Form>
                <Card variant="outlined">
                  <CardHeader
                    title={
                      componentType === "create"
                        ? "Create First Customers"
                        : "Update the First Customers"
                    }
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="name"
                          label="Name"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="email"
                          label="Email"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="contactNumber"
                          label="Contact Number"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="numberOFRooms"
                          label="Number OF Rooms"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="hotelAddress"
                          label="Hotel Address"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                          rows={3}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="referalLink"
                          label="Referal Link (Optional)"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="description"
                          label="Description"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardActions>
                    {callingBackend ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.button}
                        color={
                          componentType === "create" ? "primary" : "secondary"
                        }
                        type="submit"
                        disabled={!dirty || !isValid}
                      >
                        {componentType === "create" ? "create" : "update"}
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {alert.showAlert && (
        <Grid item md={12}>
          <Alert
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, showAlert: false })}
          >
            {alert.message}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default CreateFirstCustomers;
