import React from 'react';
import { useRoutes } from 'react-router-dom';
import Login from '../src/components/layouts/Login';
import Page404 from './components/layouts/Page404';
// import SetPassword from './components/layouts/SetPassword';
// import ResetPassword from './components/layouts/ResetPassword';

// ADMIN
import Dashboard from './screens/Admin/Dashboard/Dashboard';
import CreateItem from './screens/Item/CreateItem';
import ViewItemList from "./screens/Item/ViewItemList";
import CreateHotelAdmin from 'screens/Admin/HotelAdmin/CreateHotelAdmin';
import ViewHotelAdmins from 'screens/Admin/HotelAdmin/ViewHotelAdmins';
import SelectItem from 'screens/Admin/SelectItem/SelectItem';
import UpdateGallery from 'screens/Profile/Gallery/gallery';
import Feedback from './screens/Admin/Feedback/Feedback';

// import UsersList from './screens/Admin/User/UserList';
// import CompanyLogin from './screens/Admin/CreateCompayLogin/CreateCompayLogin';

//SETTING
import StyleSettings from 'screens/Profile/Settings/StyleSettings';

import CreateTestimonial from 'screens/Profile/Testimonial/CreateTestimonial';
import ViewTestimonials from 'screens/Profile/Testimonial/ViewTestimonials'
import CreateActivity from "screens/Profile/Activity/CreateActivity";
import ViewActivities from 'screens/Profile/Activity/ViewActivities';
import UpdateProfile from 'screens/Profile/Profile/UpdateProfile';
import UpdateAboutUs from 'screens/Profile/AboutUs/UpdateAboutUs';
import UpdateWelcomeText from 'screens/Profile/WelcomeText/UpdateWelcomeText';
import CreateService from 'screens/Profile/Service/CreateService';
import ViewServices from 'screens/Profile/Service/ViewService';
import CreateAccomodation from 'screens/Profile/Accommodation/CreateAccommodation';
import ViewAccommodation from 'screens/Profile/Accommodation/ViewAccommodation';
import CreateOffer from 'screens/Profile/Offer/CreateOffer';
import ViewOffer from 'screens/Profile/Offer/ViewOffers';
import YouTubeReference from 'screens/Profile/Gallery/YouTubeReference';

// Accounts
import BillingHistory from 'screens/Accounts/BillingHistory';
import PendingBilling from 'screens/Accounts/PendingBilling';


//FACILITY
import CreateFacility from 'screens/Facility/CreateFacility';
import ViewFacilities from 'screens/Facility/ViewFacilities';

import CreatePlaces from 'screens/Places/CreatePlaces';
import ViewPlaces from 'screens/Places/ViewPlaces';

import CreatePackage from 'screens/Package/CreatePackage';
import ViewPackage from 'screens/Package/ViewPackage';

import CreateFirstCustomers from 'screens/Admin/FirstCustomers/CreateFirstCustomers';
import ViewFirstCustomers from 'screens/Admin/FirstCustomers/ViewFirstCustomers';

import ViewBooking from 'screens/Booking/ViewBooking';

import UpdateChooesus from 'screens/Profile/Chooesus/UpdateChooesus';

export default function Pages() {
  let element = useRoutes([
    // ADMIN
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/",
      element: <Login />,
    },
    //Billing
    {
      path: "/billing-Pending",
      element: <PendingBilling />,
    },
    {
      path: "/billing-history",
      element: <BillingHistory />,
    },
    {
      path: "/create-item",
      element: <CreateItem />,
    },
    {
      path: "/view-item",
      element: <ViewItemList />,
    },
    {
      path: "/update-item",
      element: <CreateItem />,
    },
    {
      path: "/create-hotel-admin",
      element: <CreateHotelAdmin />,
    },
    {
      path: "/view-hotel-admins",
      element: <ViewHotelAdmins />,
    },
    {
      path: "/update-hotel-admin",
      element: <CreateHotelAdmin />,
    },
    {
      path: "/select-item",
      element: <SelectItem />,
    },
    {
      path: "/create-testimonial",
      element: <CreateTestimonial />,
    },
    {
      path: "/view-testimonial",
      element: <ViewTestimonials />,
    },
    {
      path: "/update-testimonial",
      element: <CreateTestimonial />,
    },
    {
      path: "/create-activity",
      element: <CreateActivity />,
    },
    {
      path: "/view-activities",
      element: <ViewActivities />,
    },
    {
      path: "/update-activity",
      element: <CreateActivity />,
    },
    {
      path: "/create-facility",
      element: <CreateFacility />,
    },
    {
      path: "/view-facilities",
      element: <ViewFacilities />,
    },
    {
      path: "/update-facility",
      element: <CreateFacility />,
    },
    {
      path: "/update-profile",
      element: <UpdateProfile />,
    },
    {
      path: "/update-aboutUs",
      element: <UpdateAboutUs />,
    },
    {
      path: "/update-welcome-text",
      element: <UpdateWelcomeText />,
    },
    {
      path: "/create-service",
      element: <CreateService />,
    },
    {
      path: "/view-service",
      element: <ViewServices />,
    },
    {
      path: "/update-service",
      element: <CreateService />,
    },
    {
      path: "/create-accommodation",
      element: <CreateAccomodation />,
    },
    {
      path: "/view-accommodation",
      element: <ViewAccommodation />,
    },
    {
      path: "/update-accommodation",
      element: <CreateAccomodation />,
    },
    {
      path: "/create-offer",
      element: <CreateOffer />,
    },
    {
      path: "/view-offer",
      element: <ViewOffer />,
    },
    {
      path: "/update-offer",
      element: <CreateOffer />,
    },
    {
      path: "/gallery",
      element: <UpdateGallery />,
    },
    {
      path: "/update-Chooesus",
      element: <UpdateChooesus />,
    },
    {
      path: "/reference-video",
      element: <YouTubeReference />,
    },
    {
      path: "/style-settings",
      element: <StyleSettings />,
    },
    {
      path: "/create-places",
      element: <CreatePlaces />,
    },
    {
      path: "/view-places",
      element: <ViewPlaces />,
    },
    {
      path: "/update-places",
      element: <CreatePlaces />,
    },
    {
      path: "/create-package",
      element: <CreatePackage />,
    },
    {
      path: "/view-package",
      element: <ViewPackage />,
    },
    {
      path: "/update-package",
      element: <CreatePackage />,
    },

    {
      path: "/create-First-Customers",
      element: <CreateFirstCustomers />,
    },
    {
      path: "/view-First-Customers",
      element: <ViewFirstCustomers />,
    },
    {
      path: "/update-First-Customers",
      element: <CreateFirstCustomers />,
    },
    {
      path: "/view-Booking",
      element: <ViewBooking />,
    },
    {
      path: "/view-feedback",
      element: <Feedback />,
    },
    // {
    //   path: '/users',
    //   element: <UsersList />,
    // },
    // {
    //   path: '/company-login',
    //   element: <CompanyLogin />,
    // },
    // {
    //   path: '/set-password/:email/:verificationToken/:isOpenEndpoint',
    //   element: <SetPassword />,
    // },
    // {
    //   path: '/forget-password/:email/:verificationToken/:isOpenEndpoint',
    //   element: <ResetPassword />,
    // },
    //404
    {
      path: "*",
      element: <Page404 />,
    },
  ]);
  return element;
}