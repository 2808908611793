import React, { Fragment } from 'react';
import { TableCell, Button } from '@material-ui/core';
import ReusableTable from '../../components/layouts/ReusableTable';
import SimpleDialog from '../../components/layouts/SimpleDialog';
import Packagepopup from "../Package/Packagepopup";

const headers = [
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Hotel Name",
    value: "hotelId",
  },
  {
    text: "Package Type",
    value: "packageType",
  },
  {
    text: "District",
    value: "district",
  },
  {
    text: "MinimumCost",
    value: "minimumCost",
  },
  {
    text: "More Details",
    value: "itemList",
  },
  {
    text: "Change Status",
    value: "status",
  },
  {
    text: "Update",
    value: "update",
  },
];
export default function ViewPackage(props) {
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleClose = (value) => {
    setOpen(false);
  };
  

  const TableB = ({ item, changeStatus, classes, onUpdate, resetPassword }) => {
    function capitalize(word) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }
    return (
      <Fragment>
        <TableCell key="name">{item.name}</TableCell>
        <TableCell key="hotelId">{item.hotelId?.name}</TableCell>
        <TableCell key="packageType">{item.packageType}</TableCell>
        <TableCell key="district">{capitalize(item.district)}</TableCell>
        <TableCell key="minimumCost">{item.minimumCost}</TableCell>
        <TableCell key="itemList">
          <Packagepopup itemList={item} changeStatus={changeStatus} classes={classes}/>
        </TableCell>

        <TableCell key="status">
            {item.status === "active" ? (
              <Button
                variant="contained"
                className={classes.red}
                onClick={() => changeStatus(item._id, "inactive")}
              >
                Deactivate
              </Button>
            ) : (
              <Button
                variant="contained"
                className={classes.green}
                onClick={() => changeStatus(item._id, "active")}
              >
                Activate
              </Button>
            )}
          </TableCell>

        <TableCell key="update">
          <Button
            variant="contained"
            className={classes.yellow}
            onClick={() => onUpdate(item._id)}
          >
            update
          </Button>
        </TableCell>
        <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title="View Package"
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl="/package/"
      statusChangeUrl="/package/status/"
      updatePath="/update-package"
      resetPasswordPath="/auth/forgot-password"
      {...props}
    />
    
  );
}
