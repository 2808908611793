import React, { Fragment } from "react";
import { TableCell, Button } from "@material-ui/core";
import ReusableTable from "../../../components/layouts/ReusableTable";
import SimpleDialog from "../../../components/layouts/SimpleDialog";
import Offerpopup from "../Offer/Offerpopup"

const headers = [
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Discount",
    value: "discount",
  },
  {
    text: "Member Count",
    value: "memberCount",
  },
  {
    text: "Start Date",
    value: "startDate",
  },
  {
    text: "End Date",
    value: "endDate",
  },
  {
    text: "Price",
    value: "price",
  },
  {
    text: "More Details",
    value: "itemList",
  },
  {
    text: "Update",
    value: "update",
  },
  {
    text: "Delete",
    value: "delete",
  },
];
export default function OfferList(props) {
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleClose = (value) => {
    setOpen(false);
  };

  const TableB = ({
    item,
    changeStatus,
    classes,
    onUpdate,
    resetPassword,
    deleteItem,
  }) => {
    return (
      <Fragment>
        <TableCell key="name">{item.name}</TableCell>
        <TableCell key="discount">{item.discount}</TableCell>
        <TableCell key="memberCount">{item.memberCount}</TableCell>
        <TableCell key="startDate">{item.startDate}</TableCell>
        <TableCell key="endDate">{item.endDate}</TableCell>
        <TableCell key="price">{item.price}</TableCell>
        <TableCell key="itemList">
          <Offerpopup itemList={item} changeStatus={changeStatus} classes={classes} />
        </TableCell>
        <TableCell key="update">
          <Button
            variant="contained"
            className={classes.yellow}
            onClick={() => onUpdate(item._id, item)}
          >
            update
          </Button>
        </TableCell>
        <TableCell key="delete">
          <Button
            variant="contained"
            className={classes.red}
            onClick={() => deleteItem(item._id)}
          >
            Delete
          </Button>
        </TableCell>
        <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title="View Offers"
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl="/offer/"
      statusChangeUrl="/item/status/"
      updatePath="/update-offer/"
      deleteItemPath="/offer"
      resetPasswordPath="/auth/forgot-password"
      {...props}
    />
  );
}
