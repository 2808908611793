import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const app = initializeApp({
  apiKey: 'AIzaSyCxo3oKGjRFuw4T0RvyiyHKfAthebkekz8',
  authDomain: 'mr-hotel-admin.firebaseapp.com',
  projectId: 'mr-hotel-admin',
  storageBucket: 'mr-hotel-admin.appspot.com',
  messagingSenderId: '100133064580',
  appId: '1:100133064580:web:d9207dac7cfff4d6a1d7d1',
});

const storage = getStorage(app);
export default storage;
