import React, { useState, useEffect } from 'react';
import "./Card.css";
import { Grid } from "@material-ui/core";
import axios from 'libs/axios';
import Alert from '@material-ui/lab/Alert';
import ViewBooking from "../../Booking/ViewBooking"

export default function Card() {
  const [count, setCount] = useState([]);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const countLoad = async () => {
    try {
        const { data } = await axios.get(`/analytics/`);
        console.log(data,"anly")
        setCount(data);

    } catch (error) {
        setAlert({
            showAlert: true,
            severity: 'success',
            message: 'Count data load is faild!',
        });
        setTimeout(() => setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Count data load is faild!',
        }), 5000);
    }
  };

  useEffect(() => {
    countLoad();
  }, [])

  return (
    <Grid container className='dashboard' spacing={2} xs={12} md={12} xm={12} lg={12} >
      <Grid item className='mainCard' xs={12} md={12} xm={12} lg={3} >
        <div className='card1'>
          <div>
            <h1 className= 'cardh1'>Users </h1>
            <h2 className= 'cardh2'>{count.userCount}</h2>
          </div>
          <div className='bottomSentence'>
            <h1 className= 'cardh1'> Hotels </h1> 
            <h2 className= 'cardh2'>{count.hotelCount}</h2>
          </div>
        </div>
      </Grid>
      <Grid item className='mainCard' xs={12} md={12} xm={12} lg={3}>
        <div className='card2'>
          <div>
            <h1 className= 'cardh1'> Pending Bookings </h1>
            <h2 className= 'cardh2'>{count.pendingBookingCount}</h2>
          </div>
          <div className='bottomSentence'>
            <h1 className= 'cardh1'> Confirm Bookings </h1> 
            <h2 className= 'cardh2'>{count.confirmBookingCount}</h2>
          </div>
        </div>
      </Grid>
      <Grid item className='mainCard' xs={12} md={12} xm={12} lg={3}>
        <div className='card3'>
          <div>
            <h1 className= 'cardh1'> Packages </h1>
            <h2 className= 'cardh2'>{count.packageCount}</h2>
          </div>
          <div className='bottomSentence'>
            <h1 className= 'cardh1'> Placeses </h1> 
            <h2 className= 'cardh2'>{count.placesCount}</h2>
          </div>
        </div>
      </Grid>
      <Grid item className='mainCard' xs={12} md={12} xm={12} lg={3}>
        <div className='card4'>
          <>
          <h1 className= 'cardh1'> Customers </h1>
          <h2 className= 'cardh2'>{count.firstCustomersCount}</h2>
          </>
          <div className='bottomSentence'>
            <h1 className= 'cardh1'> Sels</h1> 
            {/* <h2 className= 'cardh2'>{count.onboardFirstCustomersCount}</h2> */}
          </div>
        </div>
      </Grid>
      {alert.showAlert && (
        <Grid item md={12}>
          <Alert
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, showAlert: false })}
          >
            {alert.message}
          </Alert>
        </Grid>
      )}
      <Grid item className='mainCard' md={12} xm={12} xs={12} lg={12}>
        <ViewBooking/>
      </Grid>
    </Grid>
  );
}
