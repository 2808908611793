import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    title1: Yup.string()
    .required("title1 is required")
    .min(3, "Minimum length for the title1 is 3!")
    .max(30, "Maximum length for the title1 is 30!"),
    title2: Yup.string()
    .required("title2 is required")
    .min(3, "Minimum length for the title2 is 3!")
    .max(30, "Maximum length for the title2 is 30!"),
    title3: Yup.string()
    .required("title3 is required")
    .min(3, "Minimum length for the title3 is 3!")
    .max(30, "Maximum length for the title3 is 30!"),
    title4: Yup.string()
    .required("title4 is required")
    .min(3, "Minimum length for the title4 is 3!")
    .max(30, "Maximum length for the title4 is 30!"),

    description1: Yup.string().required("description1 is required")
    .min(3, "Minimum length for the description1 is 3!")
    .max(300, "Maximum length for the description1 is 300!"),

    description2: Yup.string().required("description2 is required")
    .min(3, "Minimum length for the description2 is 3!")
    .max(300, "Maximum length for the description2 is 300!"),

    description3: Yup.string().required("description3 is required")
    .min(3, "Minimum length for the description3 is 3!")
    .max(300, "Maximum length for the description3 is 300!"),

    description4: Yup.string().required("description4 is required")
    .min(3, "Minimum length for the description4 is 3!")
    .max(300, "Maximum length for the description4 is 300!"),

    image: Yup.string().required("image is required"),
});
