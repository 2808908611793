import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import axios from "libs/axios";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../utils/styles/styles";
import Button from "../../../components/UI/ReusableButton";
import CircularProgress from "../../../components/UI/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Formik, Form } from "formik";
import { TextField as TF } from "@material-ui/core";
import { validationSchema } from "../../../utils/validations/admin/selectItemValidation";

const SelectItem = (props) => {
  const classes = useStyles();
  const { state } = useLocation();

  const [initialValues, setInitialValues] = useState({
    itemSelect: "",
  });
  const [selectItem, setSelectItem] = useState([]);
  const [selected, setSelected] = useState("");
  const [pickUpdate, setPickUpdate] = useState(true);

  const [callingBackend, setCallingBackend] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const itemLoad = async () => {
    try {
      const { data } = await axios.get(`/item/`);
      setSelectItem(data);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Item data load is faild!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Item data load is faild!",
          }),
        5000
      );
    }
  };

  const submit = async (e, { resetForm }) => {
    try {
      setCallingBackend(true);
      await axios.put(`/user/select-hotel`, {
        itemSelect: selected,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Item selected successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Item selected successfully!",
          }),
        5000
      );
    } catch (error) {
      if (error.response.data === "item already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Item already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Item already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Item already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Item already exists!",
            }),
          5000
        );
      }
    } finally {
      setCallingBackend(false);
    }
  };

  const get = async () => {
    try {
      const { data } = await axios.get(`/user/me`);

      setInitialValues({
        itemSelect: data.itemSelect,
      });
      setSelected(data.itemSelect);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Data loading failed!",
          }),
        5000
      );
    }
  };
  useEffect(() => {
    itemLoad();
    get();
  }, []);

  useEffect(() => {
    if (state && state?.id) {
      get();
    }
    setTimeout(() => setIsLoading(false), 1000);
    // eslint-disable-next-line
  }, [state?.id]);

  const name = selectItem.find((i) => i._id === selected);

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Card variant="outlined">
                  <CardHeader title="Select a Item" />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Autocomplete
                          options={selectItem}
                          value={{ name: name?.name }}
                          name="itemSelect"
                          onChange={(event, newValue) => {
                            setSelected(newValue._id);
                            setPickUpdate(false);
                            // handleClick(req, newValue);
                            // setReq(newValue);
                          }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TF
                              {...params}
                              label="Selected Item "
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardActions>
                    {callingBackend ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        type="submit"
                        disabled={
                          (pickUpdate && !dirty) || !isValid || !selected
                        }
                      >
                        Select
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {alert.showAlert && (
        <Grid item md={12}>
          <Alert
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, showAlert: false })}
          >
            {alert.message}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default SelectItem;
