import React, { Fragment } from 'react';
import { TableCell, Button } from '@material-ui/core';
import ReusableTable from '../../components/layouts/ReusableTable';
import SimpleDialog from '../../components/layouts/SimpleDialog';
import Item from "../Item/Itempopup";

const headers = [
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Type",
    value: "type",
  },
  {
    text: "District",
    value: "district",
  },
  {
    text: "Phone Number",
    value: "phoneNumber",
  },
  {
    text: "More Details",
    value: "itemList",
  },
  {
    text: "Change Status",
    value: "status",
  },
  {
    text: "Update",
    value: "update",
  },
];
export default function UsersList(props) {
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleClose = (value) => {
    setOpen(false);
  };

  const TableB = ({ item, changeStatus, classes, onUpdate, resetPassword }) => {
    function capitalize(word) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }
    return (
      <Fragment>
        <TableCell key="name">{item.name}</TableCell>
        <TableCell key="type">{capitalize(item.type)}</TableCell>
        <TableCell key="district">{item.district}</TableCell>
        <TableCell key="phoneNumber">{item.phoneNumber}</TableCell>

        <TableCell key="itemList">
          <Item itemList={item} changeStatus={changeStatus} classes={classes}/>
        </TableCell>

        <TableCell key="status">
          {item.status === "active" ? (
            <Button
              variant="contained"
              className={classes.red}
              onClick={() => changeStatus(item._id, "inactive")}
            >
              Deactivate
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.green}
              onClick={() => changeStatus(item._id, "active")}
            >
              Activate
            </Button>
          )}
        </TableCell>
        <TableCell key="update">
          <Button
            variant="contained"
            className={classes.yellow}
            onClick={() => onUpdate(item._id)}
          >
            update
          </Button>
        </TableCell>
        <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title="View Items"
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl="/item/"
      statusChangeUrl="/item/status/"
      updatePath="/update-item/"
      resetPasswordPath="/auth/forgot-password"
      {...props}
    />
  );
}
