import React, { Fragment } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  Avatar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import FaceIcon from "@material-ui/icons/Face";
import { makeStyles } from "@material-ui/core";
import { logout, toggleDrawer } from "../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  self: {
    backgroundColor: "#3D424A",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  flexGrow: {
    marginRight: "auto",
  },
  chipStyles: {
    marginLeft: "1rem",
    marginRight: "1rem",
    marginTop: "0.1rem",
    backgroundColor: "white",
  },
  mobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  button: {
    display: "flex",
    flexDirection: "row-reverse",
    flexGrow: 1,
  },
  headingp:{
    color:"#fff",
    fontFamily: "'Poppins', Arial, sans-serif",
    fontWeight:800,
    fontSize: "2rem",
    fontStyle: "normal",
    backgroundColor:"#3D424A",
    width: "100%",
  },
}));

function NavBar(props) {
  const classes = useStyles();

  let navigate = useNavigate();

  let dispatch = useDispatch();

  let token = useSelector((state) => state.auth.accessToken);

  let name = 'Shop Admin';

  const onLogout = () => {
    dispatch(logout());
    navigate("/");
    handleClose();
  };

  const toggle = () => {
    dispatch(toggleDrawer());
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <AppBar className={classes.self} position="fixed">
        <Toolbar>
          {token && (
            <IconButton
              onClick={toggle}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          )}

          <div claclassName={classes.heading2}>
            <p className={classes.headingp}>Mr.Traveller</p>
          </div>

          {token && (
            <Fragment>
              <div className={classes.mobile}>
                <Chip
                  icon={<FaceIcon />}
                  label={name}
                  variant="outlined"
                  className={classes.chipStyles}
                />
              </div>
              <div className={classes.button}>
                {/* <Button
                  style={{ color: 'white' }}
                  variant='outlined'
                  onClick={() => onLogout()}
                >
                  <span style={{ marginRight: '0.2rem' }}>logout </span>
                  <ExitToAppIcon style={{ color: 'green' }} />
                </Button> */}
                <Avatar onClick={handleClick}>
                  {name?.split(" ")[1]?.charAt(0)}
                </Avatar>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={onLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default NavBar;
