import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Box,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import axios from "libs/axios";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../utils/styles/styles";
import Button from "../../../components/UI/ReusableButton";
import CircularProgress from "../../../components/UI/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import storage from "../../../firebase/firebase";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { validationSchema } from "../../../utils/validations/admin/aboutUsValidation";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const UpdateAboutUs = (props) => {
  const classes = useStyles();
  const { state } = useLocation();

  const [initialValues, setInitialValues] = useState({
    aboutUstitle: "",
    aboutUsdescription: "",
    aboutUsbackgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
  });
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);
  const [callingBackend, setCallingBackend] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);
    const [percent, setPercent] = useState(0);
    const [progbar, setprogbar] = useState(false);
    const [defaultImageUpload, setDefaultImageUpload] = useState(false);


  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e, { resetForm }) => {
      try {
        console.log("update")
      setCallingBackend(true);
      await axios.put(`/item/aboutUs-update`, {
        aboutUstitle: e.aboutUstitle,
        aboutUsdescription: e.aboutUsdescription,
        aboutUsbackgroundImage: e.aboutUsbackgroundImage,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "About Us Update successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "About Us Update successfully!",
          }),
        5000
        );
    } catch (error) {
      if (error.response.data === "About Us Update already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "About Us Update already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "About Us Update already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "About Us Update already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "About Us Update already exists!",
            }),
          5000
        );
      }
    } finally {
      setCallingBackend(false);
    }
  };

  const get = async (id) => {
    try {
      const { data } = await axios.get(`/item/update-data`);
      setInitialValues({
        aboutUstitle: data.aboutUstitle,
        aboutUsdescription: data.aboutUsdescription,
        aboutUsbackgroundImage: data.aboutUsbackgroundImage,
      });
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Data loading failed!",
          }),
        5000
      );
    }
  };
  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (state && state?.id) {
      get();
    }
    setTimeout(() => setIsLoading(false), 1000);
    // eslint-disable-next-line
  }, [state?.id]);

  const handleImageAsFile1 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue("aboutUsbackgroundImage", url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };

      const removebackgroundImage = (setFieldValue) => {
        setFieldValue(
          "aboutUsbackgroundImage",
          "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
        );
      };

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Card variant="outlined">
                  <CardHeader title="About Us" />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="aboutUstitle"
                          label="Title"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="aboutUsdescription"
                          label="Description"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                          rows={4}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo1">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo1"
                                  name="upload-photo1"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile1(
                                      e,
                                      setFieldValue,
                                      "aboutUsbackgroundImage",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Background Image upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.aboutUsbackgroundImage && (
                                <img
                                  src={values.aboutUsbackgroundImage}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.aboutUsbackgroundImage !== "" &&
                              values.aboutUsbackgroundImage !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) =>
                                      removebackgroundImage(setFieldValue)
                                    }
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardActions>
                    {callingBackend ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        type="submit"
                        disabled={!dirty || !isValid}
                      >
                        Save
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {alert.showAlert && (
        <Grid item md={12}>
          <Alert
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, showAlert: false })}
          >
            {alert.message}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default UpdateAboutUs;
