import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Box,
} from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "libs/axios";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../utils/styles/styles";
import Button from "../../components/UI/ReusableButton";
import CircularProgress from "../../components/UI/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { TextField as TF } from "@material-ui/core";
import { validationSchema } from "../../utils/validations/jobs/packageValidation";


import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import storage from '../../firebase/firebase';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';



const CreatePackage = (props) => {
  const classes = useStyles();
  const { state } = useLocation();
  let navigate = useNavigate();

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);

  const [initialValues, setInitialValues] = useState({
    name: "",
    hotelId: "",
    minimumCost: "",
    packageType: "",
    district: "",
    description: "",
    maplink: "",
    googleLocation: "",
    facility: '',
    image1:
      'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c',
    image2:
      'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c',
    image3:
      'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c',
    image4:
      'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c',
    image5:
      'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c',
  });
  const [componentType, setComponentType] = useState("create");
  const [selectItem, setSelectItem] = useState([]);
  const [sltitem, setSltitem] = useState("");


  const [callingBackend, setCallingBackend] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });


  const [packag, setpackag] = useState("");
  const packageType = [
    "dayout",
    "honeymoon",
  ];

  const [defaultImageUpload, setDefaultImageUpload] = useState(false);
  const [progbar, setprogbar] = useState(false);
  const [percent, setPercent] = useState(0);

  const [facility, setFacility] = useState([]);

  const [pickUpdate, setPickUpdate] = useState(true);
  const [dis, setdis] = useState("");
  const district = [
    "Colombo",
    "Gampaha",
    "Kalutara",
    "Kandy",
    "Matale",
    "Nuwara-Eliya",
    "Galle",
    "Matara",
    "Hambantota",
    "Jaffna",
    "Kilinochchi",
    "Mannar",
    "Vavuniya",
    "Mullaitivu",
    "Batticaloa",
    "Ampara",
    "Trincomalee",
    "Kurunegala",
    "Puttalam",
    "Anuradhapura",
    "Polonnaruwa",
    "Badulla",
    "Moneragala",
    "Ratnapura",
    "Kegalle",
  ];



  const itemLoad = async () => {
    try {
      const { data } = await axios.get(`/item/`);
      const facilityData = await axios.get(`/facility/`);
      if (data?.facility?.length) {
        const getStatus = (item) => {
          const fac = data?.facility || [];
          const status = fac.find((i) => i.facilityId === item);

          if (status) {
            return true;
          } else {
            return false;
          }
        };
        const modify = facilityData?.data?.map((item) => {
          return { ...item, checked: getStatus(item._id) };
        });
        setFacility(modify);
      } else {
        setFacility(facilityData?.data);
      }
      setSelectItem(data);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Item data load is faild!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Item data load is faild!",
          }),
        5000
      );
    }
  };



  const submit = async (e, { resetForm }) => {
    if (componentType === "create") {
      try {
        setCallingBackend(true);
        const modify = facility
          .filter((i) => i.checked)
          .map((item) => {
            return {
              facilityId: item._id,
              icon: item.icon,
              name: item.name,
            };
          });
        await axios.post("/package/", {
          name: e.name,
          hotelId: sltitem,
          minimumCost: e.minimumCost,
          packageType: packag,
          district: dis,
          description: e.description,
          maplink: e.maplink,
          googleLocation: e.googleLocation,
          facility: modify,
          image1: e.image1,
          image2: e.image2,
          image3: e.image3,
          image4: e.image4,
          image5: e.image5,
        });
        resetForm();
        setpackag("");
        setSltitem("");
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Package created successfully!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Package created successfully!",
            }),
          5000
        );
      } catch (error) {
        if (error.response.data === "Package already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Package already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Package already exists!",
              }),
            5000
          );
        } else if (error.response.status === 401) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Unauthorized Access!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Unauthorized Access!",
              }),
            5000
          );
        } else if (error.response.status === 500) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Server Error!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Server Error!",
              }),
            5000
          );
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Package already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Package already exists!",
              }),
            5000
          );
        }
      } finally {
        setCallingBackend(false);
      }
    } else {
      try {
        setCallingBackend(true);
        const modify = facility
        .filter((i) => i.checked)
        .map((item) => {
          return {
            facilityId: item._id,
            icon: item.icon,
            name: item.name,
          };
        });
        await axios.put(`/package/${state.id}`, {
          name: e.name,
          hotelId: sltitem,
          minimumCost: e.minimumCost,
          packageType: packag,
          district: dis,
          description: e.description,
          maplink: e.maplink,
          googleLocation: e.googleLocation,
          facility: modify,
          image1: e.image1,
          image2: e.image2,
          image3: e.image3,
          image4: e.image4,
          image5: e.image5,
        });
        navigate("/view-package");
      } catch (error) {
        if (error.response.data === "Package already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Package already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Package already exists!",
              }),
            5000
          );
        } else if (error.response.status === 401) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Unauthorized Access!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Unauthorized Access!",
              }),
            5000
          );
        } else if (error.response.status === 500) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Server Error!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Server Error!",
              }),
            5000
          );
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Package already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Package already exists!",
              }),
            5000
          );
        }
      } finally {
        setCallingBackend(false);
      }
    }
  };

  const handleImageAsFile1 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue('image1', url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };

  const handleImageAsFile2 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue('image2', url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };

  const handleImageAsFile3 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue('image3', url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };

  const handleImageAsFile4 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue('image4', url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };

  const handleImageAsFile5 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue('image5', url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };


  const removeImage1 = (setFieldValue) => {
    setFieldValue(
      'image1',
      'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c'
    );
  };
  const removeImage2 = (setFieldValue) => {
    setFieldValue(
      'image2',
      'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c'
    );
  };
  const removeImage3 = (setFieldValue) => {
    setFieldValue(
      'image3',
      'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c'
    );
  };
  const removeImage4 = (setFieldValue) => {
    setFieldValue(
      'image4',
      'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c'
    );
  };
  const removeImage5 = (setFieldValue) => {
    setFieldValue(
      'image5',
      'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c'
    );
  };

  


  const get = async (id) => {
    try {
      if(id){
        const { data } = await axios.get(`/package/${id}`);
        const facilityData = await axios.get(`/facility/`);
      if (data?.facility?.length) {
        const getStatus = (item) => {
          const fac = data?.facility || [];
          const status = fac.find((i) => i.facilityId === item);

          if (status) {
            return true;
          } else {
            return false;
          }
        };
        const modify = facilityData?.data?.map((item) => {
          return { ...item, checked: getStatus(item._id) };
        });
        setFacility(modify);
      } else {
        setFacility(facilityData?.data);
      }
        setInitialValues({
          name: data.name,
          hotelId: data.hotelId,
          minimumCost: data.minimumCost,
          packageType: data.packageType,
          district: data.district,
          description: data.description,
          maplink: data.maplink,
          googleLocation: data.googleLocation,
          image1: data.image1,
          image2: data.image2,
          image3: data.image3,
          image4: data.image4,
          image5: data.image5,
        });
        setSltitem(data.hotelId);
        setdis(data.district);
        setpackag(data.packageType);
      }

    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Data loading failed!",
          }),
        5000
      );
    }
  };
  

  useEffect(() => {
    itemLoad();
  }, []);



  const name = selectItem.find((i) => i._id === sltitem);


  useEffect(() => {

    if (state && state?.id) {
      setComponentType('update');
      get(state?.id);
    }
    setTimeout(() => setIsLoading(false), 1000);
    // eslint-disable-next-line
  }, [state?.id]);

  const changeFacility = (id, checked) => {
    const modify = facility?.map((item) => {
      if (item._id === id) {
        return { ...item, checked: !checked };
      } else {
        return item;
      }
    });
    setFacility(modify);
  };


  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            values,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Card variant="outlined">
                  <CardHeader
                    title={
                      componentType === "create"
                        ? "Create a Package"
                        : "Update the Package"
                    }
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="name"
                          label="Name"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Autocomplete
                          value={{ name: name?.name }}
                          options={selectItem} //array
                          name="itemSelect"
                          onChange={(event, newValue) => {
                            setSltitem(newValue._id);
                          }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TF
                              {...params}
                              label="Select Item "
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="minimumCost"
                          label="Minimum Cost"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Autocomplete
                          value={packag}
                          options={packageType}
                          name="packageType"
                          onChange={(event, newValue) => {
                            setpackag(newValue);
                          }}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TF
                              {...params}
                              label="packageType "
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <Autocomplete
                          value={dis}
                          options={district}
                          name="district"
                          onChange={(event, newValue) => {
                            setdis(newValue);
                            setPickUpdate(false);
                          }}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TF
                              {...params}
                              label="district "
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="description"
                          label="Description"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="maplink"
                          label="Map Link"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="googleLocation"
                          label="google Location"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                        />
                      </Grid>

                      <FormGroup row>
                        {facility?.map((item, index) => (
                          <FormControlLabel
                            index={index}
                            control={
                              <Checkbox
                                checked={item?.checked || false}
                                onChange={() => {
                                  changeFacility(item._id, item.checked);
                                }}
                                name='checkedA'
                                color='primary'
                              />
                            }
                            label={item.name}
                          />
                        ))}
                      </FormGroup>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          {defaultImageUpload ? (
                            'Uploading...'
                          ) : (
                            <Fragment>
                              <div className={classes.viewButton}>
                                <label htmlFor='upload-photo1'>
                                  <input
                                    style={{
                                      display: 'none',
                                      marginTop: '1rem',
                                    }}
                                    id='upload-photo1'
                                    name='upload-photo1'
                                    type='file'
                                    accept='image/jpeg,image/png,image/webp'
                                    onChange={(e) => {
                                      e.persist();
                                      handleImageAsFile1(
                                        e,
                                        setFieldValue,
                                        'image1',
                                        values
                                      );
                                    }}
                                  />

                                  <Button
                                    color='secondary'
                                    variant='contained'
                                    component='span'
                                    size='small'
                                    style={{
                                      marginBottom: '1rem',
                                      marginTop: '1rem',
                                    }}
                                  >
                                    Image 1 upload
                                  </Button>
                                </label>
                              </div>
                              <div>
                                {progbar && (
                                  <BorderLinearProgress
                                    variant='determinate'
                                    style={{ width: '100%' }}
                                    value={percent}
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  flexDirection: 'column',
                                }}
                              >
                                {values.image1 && (
                                  <img
                                    src={values.image1}
                                    alt='images'
                                    style={{
                                      maxHeight: '200px',
                                      marginTop: '1.5rem',
                                    }}
                                  />
                                )}
                                {values.image1 !== '' &&
                                  values.image1 !==
                                  'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c' ? (
                                  <Box ml={2} display='inline'>
                                    <Button
                                      style={{ marginTop: '1.5rem' }}
                                      color='inherit'
                                      variant='contained'
                                      component='span'
                                      size='small'
                                      onClick={(e) =>
                                        removeImage1(setFieldValue)
                                      }
                                    >
                                      Remove image
                                    </Button>
                                  </Box>
                                ) : (
                                  ''
                                )}
                              </div>
                            </Fragment>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          {defaultImageUpload ? (
                            'Uploading...'
                          ) : (
                            <Fragment>
                              <div className={classes.viewButton}>
                                <label htmlFor='upload-photo2'>
                                  <input
                                    style={{
                                      display: 'none',
                                      marginTop: '1rem',
                                    }}
                                    id='upload-photo2'
                                    name='upload-photo2'
                                    type='file'
                                    accept='image/jpeg,image/png,image/webp'
                                    onChange={(e) => {
                                      e.persist();
                                      handleImageAsFile2(
                                        e,
                                        setFieldValue,
                                        'image2',
                                        values
                                      );
                                    }}
                                  />

                                  <Button
                                    color='secondary'
                                    variant='contained'
                                    component='span'
                                    size='small'
                                    style={{
                                      marginBottom: '1rem',
                                      marginTop: '1rem',
                                    }}
                                  >
                                    Image 2 upload
                                  </Button>
                                </label>
                              </div>
                              <div>
                                {progbar && (
                                  <BorderLinearProgress
                                    variant='determinate'
                                    style={{ width: '100%' }}
                                    value={percent}
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  flexDirection: 'column',
                                }}
                              >
                                {values.image2 && (
                                  <img
                                    src={values.image2}
                                    alt='images'
                                    style={{
                                      maxHeight: '200px',
                                      marginTop: '1.5rem',
                                    }}
                                  />
                                )}
                                {values.image2 !== '' &&
                                  values.image2 !==
                                  'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c' ? (
                                  <Box ml={2} display='inline'>
                                    <Button
                                      style={{ marginTop: '1.5rem' }}
                                      color='inherit'
                                      variant='contained'
                                      component='span'
                                      size='small'
                                      onClick={(e) =>
                                        removeImage2(setFieldValue)
                                      }
                                    >
                                      Remove image
                                    </Button>
                                  </Box>
                                ) : (
                                  ''
                                )}
                              </div>
                            </Fragment>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          {defaultImageUpload ? (
                            'Uploading...'
                          ) : (
                            <Fragment>
                              <div className={classes.viewButton}>
                                <label htmlFor='upload-photo3'>
                                  <input
                                    style={{
                                      display: 'none',
                                      marginTop: '1rem',
                                    }}
                                    id='upload-photo3'
                                    name='upload-photo3'
                                    type='file'
                                    accept='image/jpeg,image/png,image/webp'
                                    onChange={(e) => {
                                      e.persist();
                                      handleImageAsFile3(
                                        e,
                                        setFieldValue,
                                        'image3',
                                        values
                                      );
                                    }}
                                  />

                                  <Button
                                    color='secondary'
                                    variant='contained'
                                    component='span'
                                    size='small'
                                    style={{
                                      marginBottom: '1rem',
                                      marginTop: '1rem',
                                    }}
                                  >
                                    Image 3 upload
                                  </Button>
                                </label>
                              </div>
                              <div>
                                {progbar && (
                                  <BorderLinearProgress
                                    variant='determinate'
                                    style={{ width: '100%' }}
                                    value={percent}
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  flexDirection: 'column',
                                }}
                              >
                                {values.image3 && (
                                  <img
                                    src={values.image3}
                                    alt='images'
                                    style={{
                                      maxHeight: '200px',
                                      marginTop: '1.5rem',
                                    }}
                                  />
                                )}
                                {values.image3 !== '' &&
                                  values.image3 !==
                                  'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c' ? (
                                  <Box ml={2} display='inline'>
                                    <Button
                                      style={{ marginTop: '1.5rem' }}
                                      color='inherit'
                                      variant='contained'
                                      component='span'
                                      size='small'
                                      onClick={(e) =>
                                        removeImage3(setFieldValue)
                                      }
                                    >
                                      Remove image
                                    </Button>
                                  </Box>
                                ) : (
                                  ''
                                )}
                              </div>
                            </Fragment>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          {defaultImageUpload ? (
                            'Uploading...'
                          ) : (
                            <Fragment>
                              <div className={classes.viewButton}>
                                <label htmlFor='upload-photo4'>
                                  <input
                                    style={{
                                      display: 'none',
                                      marginTop: '1rem',
                                    }}
                                    id='upload-photo4'
                                    name='upload-photo4'
                                    type='file'
                                    accept='image/jpeg,image/png,image/webp'
                                    onChange={(e) => {
                                      e.persist();
                                      handleImageAsFile4(
                                        e,
                                        setFieldValue,
                                        'image4',
                                        values
                                      );
                                    }}
                                  />

                                  <Button
                                    color='secondary'
                                    variant='contained'
                                    component='span'
                                    size='small'
                                    style={{
                                      marginBottom: '1rem',
                                      marginTop: '1rem',
                                    }}
                                  >
                                    Image 4 upload
                                  </Button>
                                </label>
                              </div>
                              <div>
                                {progbar && (
                                  <BorderLinearProgress
                                    variant='determinate'
                                    style={{ width: '100%' }}
                                    value={percent}
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  flexDirection: 'column',
                                }}
                              >
                                {values.image4 && (
                                  <img
                                    src={values.image4}
                                    alt='images'
                                    style={{
                                      maxHeight: '200px',
                                      marginTop: '1.5rem',
                                    }}
                                  />
                                )}
                                {values.image4 !== '' &&
                                  values.image4 !==
                                  'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c' ? (
                                  <Box ml={2} display='inline'>
                                    <Button
                                      style={{ marginTop: '1.5rem' }}
                                      color='inherit'
                                      variant='contained'
                                      component='span'
                                      size='small'
                                      onClick={(e) =>
                                        removeImage4(setFieldValue)
                                      }
                                    >
                                      Remove image
                                    </Button>
                                  </Box>
                                ) : (
                                  ''
                                )}
                              </div>
                            </Fragment>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          {defaultImageUpload ? (
                            'Uploading...'
                          ) : (
                            <Fragment>
                              <div className={classes.viewButton}>
                                <label htmlFor='upload-photo5'>
                                  <input
                                    style={{
                                      display: 'none',
                                      marginTop: '1rem',
                                    }}
                                    id='upload-photo5'
                                    name='upload-photo5'
                                    type='file'
                                    accept='image/jpeg,image/png,image/webp'
                                    onChange={(e) => {
                                      e.persist();
                                      handleImageAsFile5(
                                        e,
                                        setFieldValue,
                                        'image5',
                                        values
                                      );
                                    }}
                                  />

                                  <Button
                                    color='secondary'
                                    variant='contained'
                                    component='span'
                                    size='small'
                                    style={{
                                      marginBottom: '1rem',
                                      marginTop: '1rem',
                                    }}
                                  >
                                    Image 5 upload
                                  </Button>
                                </label>
                              </div>
                              <div>
                                {progbar && (
                                  <BorderLinearProgress
                                    variant='determinate'
                                    style={{ width: '100%' }}
                                    value={percent}
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  flexDirection: 'column',
                                }}
                              >
                                {values.image5 && (
                                  <img
                                    src={values.image5}
                                    alt='images'
                                    style={{
                                      maxHeight: '200px',
                                      marginTop: '1.5rem',
                                    }}
                                  />
                                )}
                                {values.image5 !== '' &&
                                  values.image5 !==
                                  'https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c' ? (
                                  <Box ml={2} display='inline'>
                                    <Button
                                      style={{ marginTop: '1.5rem' }}
                                      color='inherit'
                                      variant='contained'
                                      component='span'
                                      size='small'
                                      onClick={(e) =>
                                        removeImage5(setFieldValue)
                                      }
                                    >
                                      Remove image
                                    </Button>
                                  </Box>
                                ) : (
                                  ''
                                )}
                              </div>
                            </Fragment>
                          )}
                        </Grid>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={6}>
                            <Field
                              name="pacility"
                              label="pacility"
                              component={TextField}
                              variant="outlined"
                              fullWidth
                              required
                              multiline
                            />
                          </Grid> */}
                    </Grid>
                  </CardContent>

                  <CardActions>
                    {callingBackend ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.button}
                        color={
                          componentType === "create" ? "primary" : "secondary"
                        }
                        type="submit"
                        disabled={(pickUpdate && !dirty) || !isValid || !dis}
                      >
                        {componentType === "create" ? "create" : "update"}
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {alert.showAlert && (
        <Grid item md={12}>
          <Alert
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, showAlert: false })}
          >
            {alert.message}
          </Alert>
        </Grid>
      )}
    </Grid>
  );




};

export default CreatePackage;