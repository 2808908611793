import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  welcometext: Yup.string()
    .required("Text is required")
    .min(1, "Minimum length for the text is 1!")
    .max(150, "Maximum length for the text is 150!"),
  welcomedescription: Yup.string()
    .required("Description is required")
    .min(3, "Minimum length for the description is 3!")
    .max(300, "Maximum length for the description is 300!"),
  welcomeimage1: Yup.string(),
  welcomeimage2: Yup.string(),
  welcomeimage3: Yup.string(),
  welcomeimage4: Yup.string(),
  welcomeimage5: Yup.string(),
});
