import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import axios from "libs/axios";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../utils/styles/styles";
import Button from "../../../components/UI/ReusableButton";
import CircularProgress from "../../../components/UI/CircularProgress";

import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { validationSchema } from "../../../utils/validations/admin/videoValidation";

const VideoUpload = (props) => {
  const classes = useStyles();
  const { state } = useLocation();

  const [initialValues, setInitialValues] = useState({
    videolink: "",
  });

  const [callingBackend, setCallingBackend] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e, { resetForm }) => {
    try {
      console.log("update");
      setCallingBackend(true);
      await axios.put(`/item/gallery`, {
        videolink: e.videolink,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Video Upload successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Video Upload successfully!",
          }),
        5000
      );
    } catch (error) {
      if (error.response.data === "Video Upload already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Video Upload already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Video Upload already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Video Upload already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Video Upload already exists!",
            }),
          5000
        );
      }
    } finally {
      setCallingBackend(false);
    }
  };

  const get = async (id) => {
    try {
      const { data } = await axios.get(`/item/update-data`);
      setInitialValues({
        videolink: data.gallery.videolink,
      });
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Data loading failed!",
          }),
        5000
      );
    }
  };
  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (state && state?.id) {
      get();
    }
    setTimeout(() => setIsLoading(false), 1000);
    // eslint-disable-next-line
  }, [state?.id]);

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Card variant="outlined">
                  <CardHeader title="Upload the Video" />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="videolink"
                          label="Video link"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardActions>
                    {callingBackend ? (
                      <CircularProgress />
                    ) : (
                      // <Button
                      //   variant="contained"
                      //   className={classes.button}
                      //   color="primary"
                      //   type="submit"
                      //   disabled={!dirty || !isValid}
                      // >
                      //   Save
                      // </Button>
                      <Button
                      variant="contained"
                      className={classes.button}
                      color="primary"
                      type="submit"
                      disabled={!dirty}
                    >
                      Save
                    </Button>
                    )}
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {alert.showAlert && (
        <Grid item md={12}>
          <Alert
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, showAlert: false })}
          >
            {alert.message}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default VideoUpload;
