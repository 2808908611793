import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import style from './firstCustomers.css';

const styles = (theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[600],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


export default function CustomizedDialogs({ itemList }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} >
                MORE
            </Button>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={"lg"} maxWidth={"md"}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Company Details
                    </DialogTitle>
                    <p className={style} > Name : {itemList.name} </p>
                    <p className={style} > Hotel Address : {itemList.hotelAddress} </p>
                    <p className={style} > Email : {itemList.email} </p>
                    <p className={style} > Contact Number : {itemList.contactNumber} </p>
                    <p className={style} > Number OF Rooms : {itemList.numberOFRooms} </p>
                    <p className={style} > Referal Link : <a href={itemList.referalLink} target="_blank" rel="noreferrer" >Click to Open!</a> </p>
            </Dialog>
        </div>
    );
}