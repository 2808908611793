import React, { Fragment } from "react";
import { TableCell, Button } from "@material-ui/core";
import ReusableTable from "../../../components/layouts/ReusableTable";
import SimpleDialog from "../../../components/layouts/SimpleDialog";
import FirstCustomers from "./FirstCustomers";
import Conversaction from "./Conversaction";

const headers = [
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Hotel Address",
    value: "hotelAddress",
  },
  {
    text: "Conversaction",
    value: "conversaction",
  },
  {
    text: "Description",
    value: "description",
  },
  {
    text: "More Details",
    value: "itemList",
  },
  {
    text: "Change Status",
    value: "status",
  },
  {
    text: "Update",
    value: "update",
  },
];

export default function ViewMeta(props) {
  const [open, setOpen] = React.useState(false);

  // eslint-disable-next-line
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleClose = (value) => {
    setOpen(false);
  };

  const TableB = ({
    item,
    changeStatus,
    classes,
    onUpdate,
  }) => {
    return (
      <Fragment>
        <TableCell key="name">{item.name}</TableCell>
        <TableCell key="hotelAddress">{item.hotelAddress}</TableCell>
        <TableCell key="conversaction">
          <Conversaction item={item._id} />
        </TableCell>
        <TableCell key="description">{item.description}</TableCell>
        <TableCell key="itemList">
          <FirstCustomers itemList={item} />
        </TableCell>
        <TableCell key="status">
          {item.status === "active" ? (
            <Button
              variant="contained"
              className={classes.red}
              onClick={() => changeStatus(item._id, "inactive")}
            >
              Deactivate
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.green}
              onClick={() => changeStatus(item._id, "active")}
            >
              Onboard
            </Button>
          )}
        </TableCell>
        <TableCell key="update">
          <Button
            variant="contained"
            className={classes.yellow}
            onClick={() => onUpdate(item._id)}
          >
            Update
          </Button>
        </TableCell>
        <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title="View First Customers"
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl="/firstCustomers/"
      statusChangeUrl="/firstCustomers/status/"
      onboardChangeUrl="/firstCustomers/onboard/"
      updatePath="/update-First-Customers"
      deleteItemPath="/firstCustomers/"
      {...props}
    />
  );
}
