import * as Yup from "yup";
const phoneRegExp = /^(?:7|0|(?:\+94))[0-9]{9,11}$/;

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Too Short!")
    .max(30, "Too Long!")
    .required("Name is required"),
  type: Yup.string().required("Type is required."),
  logo: Yup.string().required("Logo is required"),
  iframeLocation: Yup.string().required("Iframe location is required."),
  navbarLocation: Yup.string().required("Navigation bar location is required."),
  description: Yup.string()
    .min(3, 'Minimum length for the product name is 3!')
    .max(1000, 'Maximum length for product name is 1000!'),
  address: Yup.string()
    .min(3, "Minimum length for the address is 3!!")
    .max(100, "Maximum length for the address is 100!")
    .required("Address is required"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required!"),
  email: Yup.string()
    .required("Email is required!")
    .trim()
    .matches(
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    )
    .max(320, "Must be less than 320 digits"),
});
