import React from 'react';
import FooterStyle from "./FooterStyle";
import PrimaryStyle from './PrimaryStyle';
import OfferStyle from './OfferStyle';
import ButtonStyle from './ButtonStyle';
import SearchBarStyle from './SearchBarStyle';

export default function StyleSettings() {
  return (
    <div>
      <PrimaryStyle />
          <FooterStyle />
          <OfferStyle />
          <ButtonStyle />
          <SearchBarStyle />
    </div>
  );
}
