import React, { Fragment } from "react";
import { TableCell} from "@material-ui/core";
import ReusableTable from "../../components/layouts/ReusableTable";
import ViewMore from "./ViewMore";

const headers = [
  {
    text: "Company",
    value: "companyName",
  },
  {
    text: "Amount",
    value: "paidAmount",
  },
  {
    text: "Submitted Date",
    value: "date",
  },
  {
    text: "Action",
    value: "itemList",
  },
];
export default function UsersList(props) {

  const TableB = ({ item, deleteItem, loadData }) => {
    return (
      <Fragment>
        <TableCell key="hotelName">{item?.hotelName}</TableCell>
        <TableCell key="paidAmount">{item?.paidAmount}</TableCell>
        <TableCell key="date">{item?.date ? new Date(item?.date).toLocaleString() : "-"}</TableCell>
        <TableCell key="itemList">
          <ViewMore itemList={item}  deleteItem={deleteItem} loadData={loadData}/>
        </TableCell>
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title="Pending"
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl="/billing/admin-pending"
      statusChangeUrl="/billing/status-update"
      resetPasswordPath="/auth/forgot-password"
      deleteItemPath='/billing/delete'
      {...props}
    />
  );
}
