// Auth actions
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const TOGGLE = "TOGGLE";

//Other actions
export const COMPANY_SWITCH= 'COMPANY_SWITCH';

//Header actions
export const SHOW_HEADER_ITEMS = 'SHOW_HEADER_ITEMS'
export const HIDE_HEADER_ITEMS = 'HIDE_HEADER_ITEMS'
