import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Box,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import axios from "libs/axios";
import storage from "../../../firebase/firebase";

import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../utils/styles/styles";
import Button from "../../../components/UI/ReusableButton";
import CircularProgress from "../../../components/UI/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { TextField as TF } from "@material-ui/core";
import { validationSchema } from "../../../utils/validations/admin/profileValidation";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const UpdateProfile = (props) => {
  const classes = useStyles();
  const { state } = useLocation();
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);
  const [initialValues, setInitialValues] = useState({
    name: "",
    type: "",
    logo: "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    iframeLocation: "",
    navbarLocation: "",
    district: "",
    address: "",
    phoneNumber: "",
    minimumCost: "",
    email: "",
    websiteLink: "",
    maplink: "",
    description: "",
  });
  const [componentType, setComponentType] = useState("create");
    const [percent, setPercent] = useState(0);
    const [progbar, setprogbar] = useState(false);
    const [defaultImageUpload, setDefaultImageUpload] = useState(false);
    const [facility, setFacility] = useState([]);

  const itemType = [
    { value: "hotel", name: "Hotel" },
    { value: "villa", name: "Villa" },
    { value: "dayOut", name: "DayOut" },
    { value: "restaurant", name: "Restaurant" },
  ];
  const [dis, setdis] = useState("");
  const district = [
    "Colombo",
    "Gampaha",
    "Kalutara",
    "Kandy",
    "Matale",
    "Nuwara-Eliya",
    "Galle",
    "Matara",
    "Hambantota",
    "Jaffna",
    "Kilinochchi",
    "Mannar",
    "Vavuniya",
    "Mullaitivu",
    "Batticaloa",
    "Ampara",
    "Trincomalee",
    "Kurunegala",
    "Puttalam",
    "Anuradhapura",
    "Polonnaruwa",
    "Badulla",
    "Moneragala",
    "Ratnapura",
    "Kegalle",
  ];

  const [callingBackend, setCallingBackend] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e, { resetForm }) => {
    try {
      setCallingBackend(true);
      const modify = facility
      .filter((i) => i.checked)
      .map((item) => {
        return {
          facilityId: item._id,
          icon: item.icon,
          name: item.name,
        };
      });
      await axios.put(`/item/profile-update/`, {
        name: e.name,
        type: e.type,
        iframeLocation: e.iframeLocation,
        navbarLocation: e.navbarLocation,
        district: dis,
        address: e.address,
        phoneNumber: e.phoneNumber,
        minimumCost: e.minimumCost,
        email: e.email,
        websiteLink: e.websiteLink,
        maplink: e.maplink,
        description: e.description,
        facility: modify,
        logo: e.logo,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Profile updated successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Profile updated successfully!",
          }),
        5000
      );
    } catch (error) {
      if (error.response.data === "Profile updated already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Profile updated already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Profile updated already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Profile updated already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Profile updated already exists!",
            }),
          5000
        );
      }
    } finally {
      setCallingBackend(false);
    }
  };


  const get = async () => {
    try {
      const { data } = await axios.get(`/item/update-data`);
      const facilityData = await axios.get(`/facility/`);
      if (data?.hotelFeature?.facility?.length) {
        const getStatus = (item) => {
          const fac = data?.hotelFeature?.facility || [];
          const status = fac.find((i) => i.facilityId === item);

          if (status) {
            return true;
          } else {
            return false;
          }
        };
        const modify = facilityData?.data?.map((item) => {
          return { ...item, checked: getStatus(item._id) };
        });
        setFacility(modify);
      } else {
        setFacility(facilityData?.data);
      }
      setInitialValues({
        name: data.name,
        type: data.type,
        iframeLocation: data.iframeLocation,
        navbarLocation: data.navbarLocation,
        district: data.district,
        address: data.address,
        phoneNumber: data.phoneNumber,
        minimumCost: data.minimumCost,
        email: data.email,
        websiteLink: data.websiteLink,
        maplink: data.maplink,
        description: data.description,
        logo: data.logo,
      });
      setdis(data.district);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Data loading failed!",
          }),
        5000
      );
    }
  };
  useEffect(() => {
    get();
  }, []);


      const handleImageAsFile1 = async (e, setFieldValue, type, values) => {
        try {
          setDefaultImageUpload(true);
          const image = e.target.files[0];
          const storageRef = ref(
            storage,
            `/files/${image.name}${image.lastModifiedDate}`
          );
          const uploadTask = uploadBytesResumable(storageRef, image);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setPercent(percent);
              if (percent === 100) {
                setTimeout(() => {
                  setprogbar(false);
                }, 3000);
              } else if (1 < percent < 99) {
                setprogbar(true);
              } else {
                setprogbar(false);
              }
            },
            (err) => console.log(err),
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                setFieldValue("logo", url);
              });
            }
          );
        } catch (error) {
          console.error(error);
        } finally {
          setDefaultImageUpload(false);
        }
  };
        const removeImage = (setFieldValue) => {
          setFieldValue(
            "logo",
            "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
          );
        };

        const changeFacility = (id, checked) => {
          const modify = facility.map((item) => {
            if (item._id === id) {
              return { ...item, checked: !checked };
            } else {
              return item;
            }
          });
          setFacility(modify);
        };


  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Card variant="outlined">
                  <CardHeader title="Update the Item" />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="name"
                          label="Name"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="type"
                          label="Type"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          select
                          required
                          SelectProps={{ native: true }}
                        >
                          {" "}
                          <option value="" style={{ display: "none" }} />
                          {itemType.map((option) => (
                            <option key={option.continent} value={option.value}>
                              {option.name}
                            </option>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="iframeLocation"
                          label="Location Iframe"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="navbarLocation"
                          label="Navigation bar Location"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Autocomplete
                          value={dis}
                          options={district}
                          name="district"
                          onChange={(event, newValue) => {
                            setdis(newValue);
                            // handleClick(req, newValue);
                            // setReq(newValue);
                          }}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TF
                              {...params}
                              label="District "
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="phoneNumber"
                          label="Phone Number"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="minimumCost"
                          label="MinimumCost"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="email"
                          label="Email"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="websiteLink"
                          label="Website Link"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="maplink"
                          label="Map Link"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="description"
                          label="Description"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                        />
                      </Grid>
                      <FormGroup row>
                        {facility.map((item, index) => (
                          <FormControlLabel
                            index={index}
                            control={
                              <Checkbox
                                checked={item?.checked || false}
                                onChange={() => {
                                  changeFacility(item._id, item.checked);
                                }}
                                name='checkedA'
                                color='primary'
                              />
                            }
                            label={item.name}
                          />
                        ))}
                      </FormGroup>
                      <Grid item xs={12} sm={6} md={6}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo1">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo1"
                                  name="upload-photo1"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile1(
                                      e,
                                      setFieldValue,
                                      "logo",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Logo upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.logo && (
                                <img
                                  src={values.logo}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.logo !== "" &&
                              values.logo !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage(setFieldValue)}
                                  >
                                    Remove logo
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          name="address"
                          label="Address"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          required
                          multiline
                          rows={4}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardActions>
                    {callingBackend ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.button}
                        color={
                          componentType === "create" ? "primary" : "secondary"
                        }
                        type="submit"
                        disabled={!dirty || !isValid || !dis}
                      >
                        save
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {alert.showAlert && (
        <Grid item md={12}>
          <Alert
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, showAlert: false })}
          >
            {alert.message}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default UpdateProfile;
