import React from "react";
import "./Card.css";
import Card from './Card'

export default function Dashboard() {
  return (
    <div>
      <h1>Dashboard</h1>
      <Card/>
    </div>
  );
}
