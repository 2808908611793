import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Minimum length for the name is 3!"),

  email: Yup.string()
    .email("Invalid email format")
    .required("Email is Required"),

  contactNumber: Yup.string()
    .matches(
      /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/,
      "Phone Number is not valid"
    )
    .required("Contact number is Required"),

  numberOFRooms: Yup.number().required("Number Of Rooms is required"),

  hotelAddress: Yup.string()
    .required("Hotel Address is required")
    .min(3, "Minimum length for the name is 3!")
    .max(300, "Maximun length for the name is 300!"),

  description: Yup.string()
    .min(3, "Minimum length for the description is 3!")
    .max(300, "Maximun length for the description is 300!")
    .required("Description is Required"),
});
