import { LOGIN, LOGOUT, TOGGLE } from "./types";
import axios from "libs/axios";
import { routes, routes2 } from "../../routes";

export const login =
  (fullName, role, accessToken, permissions, id) => (dispatch) => {
    let permittedRoutes;
    if (role === "admin") {
      // eslint-disable-next-line
      permittedRoutes = routes.filter((route) => {
        // eslint-disable-line
        const children = route.children;

        const intersection = children.filter((element) => !element.isHidden);
        if (intersection.length) {
          route.children = intersection;
          return route;
        }
      });
    }
      else if (role === "companyAdmin") {
          // eslint-disable-next-line
          permittedRoutes = routes2.filter((route) => {
              // eslint-disable-line
              const children = route.children;

              const intersection = children.filter((element) => !element.isHidden);
              if (intersection.length) {
                  route.children = intersection;
                  return route;
              }
          });
      }
      else {
      permittedRoutes = routes.filter((route) => {
        return route;
      });
    }
    // save to the session storage
    sessionStorage.setItem("accessToken", accessToken);
    sessionStorage.setItem("fullName", fullName);
    sessionStorage.setItem("role", role);
    sessionStorage.setItem("routes", JSON.stringify(permittedRoutes));
    sessionStorage.setItem("id", id);

    axios.interceptors.request.use(
      function (config) {
        config.headers["Authorization"] = "Bearer " + accessToken;
        return config;
      },
      null,
      { synchronous: true }
    );

    dispatch({
      type: LOGIN,
      payload: { accessToken, fullName, role, routes: permittedRoutes, id },
    });
  };

export const logout = () => (dispatch) => {
  // remove items from session storage
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("fullName");
  sessionStorage.removeItem("role");
  sessionStorage.removeItem("routes");
  sessionStorage.removeItem("id");

  axios.interceptors.request.use(
    (config) => {
      delete config.headers["Authorization"];
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  dispatch({
    type: LOGOUT,
  });
};

export const toggleDrawer = () => (dispatch) => {
  dispatch({
    type: TOGGLE,
  });
};
