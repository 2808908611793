//super admin
export const routes = [
  {
    name: "ADMIN",
    children: [
      {
        name: "Dashboard",
        path: "/dashboard",
      },
      {
        name: "Create Item",
        path: "/create-item",
      },
      {
        name: "View Items",
        path: "/view-item",
      },
      {
        name: "Create Hotel Admin",
        path: "/create-hotel-admin",
      },
      {
        name: "View Hotel Admins",
        path: "/view-hotel-admins",
      },
      {
        name: "Select Item",
        path: "/select-item",
      },
      {
        name: "View feedback",
        path: "/view-feedback",
      },
    ],
  },
  {
    name: "BILLING",
    children: [
      {
        name: "Pending",
        path: "/billing-Pending",
      },
      {
        name: "History",
        path: "/billing-history",
      },
    ],
  },
  {
    name: "BOOKING",
    children: [
      {
        name: "View Booking",
        path: "/view-Booking",
      },
    ],
  },
  {
    name: "SELLS",
    children: [
      {
        name: "Create First Customer",
        path: "/create-First-Customers",
      },
      {
        name: "view First Customer",
        path: "/view-First-Customers",
      },
    ],
  },
  {
    name: "PLACES",
    children: [
      {
        name: "Create Places",
        path: "/create-places",
      },
      {
        name: "View Places",
        path: "/view-places",
      },
    ],
  },
  {
    name: "PACKAGE",
    children: [
      {
        name: "Create Package",
        path: "/create-package",
      },
      {
        name: "View Package",
        path: "/view-package",
      },
    ],
  },
  {
    name: "FACILITY",
    children: [
      {
        name: "Create Facility",
        path: "/create-facility",
      },
      {
        name: "View Facilities",
        path: "/view-facilities",
      },
    ],
  },
  {
    name: "PROFILE",
    children: [
      {
        name: "Update Profile",
        path: "/update-profile",
      },
      {
        name: "Update About Us",
        path: "/update-aboutUs",
      },
      {
        name: "Update Welcome Text",
        path: "/update-welcome-text",
      },
      {
        name: "Update Chooes us",
        path: "/update-Chooesus",
      },
    ],
  },
  {
    name: "GALLERY",
    children: [
      {
        name: "Update Gallery",
        path: "/gallery",
      },
      {
        name: "Update YouTube References",
        path: "/reference-video",
      },
    ],
  },
  {
    name: "ACCOMMODATION",
    children: [
      {
        name: "Create Accommodation",
        path: "/create-accommodation",
      },
      {
        name: "View Accommodation",
        path: "/view-accommodation",
      },
    ],
  },
  {
    name: "OFFER",
    children: [
      {
        name: "Create Offer",
        path: "/create-offer",
      },
      {
        name: "View Offer",
        path: "/view-offer",
      },
    ],
  },
  {
    name: "ACTIVITY",
    children: [
      {
        name: "Create Activity",
        path: "/create-activity",
      },
      {
        name: "View Activities",
        path: "/view-activities",
      },
    ],
  },

  {
    name: "TESTIMONIAL",
    children: [
      {
        name: "Create Testimonial",
        path: "/create-testimonial",
      },
      {
        name: "View Testimonial",
        path: "/view-testimonial",
      },
    ],
  },
  {
    name: "SERVICE",
    children: [
      {
        name: "Create Service",
        path: "/create-service",
      },
      {
        name: "View Service",
        path: "/view-service",
      },
    ],
  },
  {
    name: "SETTINGS",
    children: [
      {
        name: "Style Property",
        path: "/style-settings",
      },
    ],
  },
];
export const routes2 = [
  {
    name: "ADMIN",
    children: [
      {
        name: "Dashboard",
        path: "/dashboard",
      },
    ],
  },
];