import React from 'react';
import UpdateGallery from './UpdateGallery';
import VideoUpload from './VideoUpload';

export default function gallery() {
  return (
      <>
          <UpdateGallery />
          <VideoUpload />
      </>
  )
}
