import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    name: Yup.string()
    .required('Name is required!')
    .min(3, 'Minimum length for the product name is 3!')
    .max(150, 'Maximum length for product name is 150!'),

    // hotelId: Yup.object()
    // .required('district is required!'),

    minimumCost: Yup.number()
    .required('minimumCost is required!')
    .min(3, 'Minimum length for the minimumCost is 3!'),
    description: Yup.string()
    .required('description is required!')
    .min(3, 'Minimum length for the product name is 3!')
    .max(1000, 'Maximum length for product name is 1000!'),

    // packageType: Yup.string()
    // .required('packageType is required!'),


    
    // image: Yup.string()
    // .min(5, 'Minimum length for the image url is 5!'),

});
