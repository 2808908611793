import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Minimum length for the name is 3!")
    .max(30, "Maximum length for the vehicle Rto is 30!"),
  description: Yup.string()
    .required("Description is required")
    .min(3, "Minimum length for the description is 3!")
    .max(300, "Maximum length for the vehicle Rto is 300!"),
  icon: Yup.string().min(5, "Minimum length for the icon url is 5!"),
});
