import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    name: Yup.string()
    .required('Name is required!')
    .min(3, 'Minimum length for the product name is 3!')
    .max(150, 'Maximum length for product name is 150!'),

    // district: Yup.string()
    // .required('district is required!'),

    description: Yup.string()
    .required('description is required!')
    .min(3, 'Minimum length for the product name is 3!')
    .max(300, 'Maximum length for product name is 300!'),

    
    // image: Yup.string()
    // .min(5, 'Minimum length for the image url is 5!'),

    minimumCost: Yup.number()
    .required('minimumCost is required!')
    .min(3, 'Minimum length for the minimumCost is 3!'),

    googleLocation: Yup.string()
    .required('google Location is required!'),
});
