import React, { Fragment } from 'react';
import { TableCell } from '@material-ui/core';
import ReusableTable from '../../../components/layouts/ReusableTable';
import SimpleDialog from '../../../components/layouts/SimpleDialog';

const headers = [
  {
    text: 'Feedback type',
    value: 'feedbackType',
  },
  {
    text: 'Description',
    value: 'description',
  },
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Email',
    value: 'email',
  },
];
export default function UsersList(props) {
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleClose = (value) => {
    setOpen(false);
  };

  const TableB = ({ item }) => {
    return (
      <Fragment>
        <TableCell key='feedbackType'>{item.feedbackType}</TableCell>
        <TableCell key='description'>{item.description}</TableCell>
        <TableCell key='name'>{item.name}</TableCell>
        <TableCell key='email'>{item.email}</TableCell>
        <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title='View feedback'
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl='/feedback/'
      resetPasswordPath='/auth/forgot-password'
      {...props}
    />
  );
}
