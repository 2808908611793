import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  aboutUsdescription: Yup.string()
    .required("Description is required")
    .min(3, "Minimum length for the description is 3!")
    .max(500, "Maximum length for the description is 500!"),
  aboutUstitle: Yup.string()
    .required()
    .min(3, "Minimum length for the title is 3!"),
  aboutUsbackgroundImage: Yup.string()
    .required()
    .min(3, "Minimum length for the image url is 3!"),
});
