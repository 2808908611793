import * as React from "react";
import { useState } from "react";
import { useStyles } from "../../../utils/styles/styles";
import axios from "libs/axios";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import SendIcon from "@material-ui/icons/Send";
import { validationSchema } from "../../../utils/validations/jobs/conversactionValidation";
import IconButton from "@material-ui/core/IconButton";
import "./chat.css";

export default function CustomizedDialogs({ item }) {
  const classes = useStyles();

  // data
  const initialValues = {
    company: item,
    message: "",
  };

  // eslint-disable-next-line
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  // create method
  const submit = async (e, { resetForm }) => {
    try {
      await axios.post("/companyConversaction/", e);
      resetForm();
      get(item);
    } catch (error) {
      if (error.response.status === 403) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Job role already exists!",
        });
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Job role create failed!",
        });
      }
    } finally {
    }
  };
  const [open, setOpen] = React.useState(false);
  const [chat, setChat] = React.useState([]);
  const handleClickOpen = () => {
    get(item);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const get = async (id) => {
    try {
      const { data } = await axios.get(`/companyConversaction/${id}`);
      setChat(data);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
    }
  };

  const deleteItem = async (id) => {
    try {
      await axios.delete(`/companyConversaction/${id}`);
      get(item);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
    }
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>View</Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent dividers>
          <Card
            style={{
              height: "25rem",
              overflowY: "scroll",
              marginBottom: "1.5rem",
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"}>
                <CardHeader title={"Company conversaction"} />
              </Grid>
              <Grid item md={"auto"}>
                <IconButton onClick={() => handleClose()}>
                  <HighlightOffIcon />
                </IconButton>
              </Grid>
            </Grid>
            {chat.map((chat, i) => (
              <Grid className="talk-bubble round ">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="talktext">
                    <Typography key={i}>{chat.message}</Typography>
                  </div>
                  <div>
                    <IconButton
                      variant="contained"
                      className={classes.button}
                      key={i}
                      onClick={() => deleteItem(chat.id)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </div>
                </div>
                <Typography
                  key={i}
                  style={{
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                    marginLeft: "1rem",
                  }}
                >
                  {moment(chat.createdAt).utc().format("YYYY-MM-DD")}
                </Typography>
              </Grid>
            ))}
          </Card>
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isValid, dirty, handleChange, handleBlur, values }) => {
              return (
                <Form>
                  <Card variant="outlined">
                    <Grid container spacing={1}>
                      <Grid item md={10}>
                        <CardContent>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                              <Field
                                name="message"
                                label="Message"
                                component={TextField}
                                variant="outlined"
                                fullWidth
                                required
                                multiline
                                rows={3}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Grid>
                      <Grid item md={1}>
                        <CardActions>
                          <IconButton
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            type="submit"
                            disabled={!dirty || !isValid}
                          >
                            <SendIcon />
                          </IconButton>
                        </CardActions>
                      </Grid>
                    </Grid>
                  </Card>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
