import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import axios from "libs/axios";
import Alert from '@material-ui/lab/Alert';
import ConfirmPopup from "./ConfirmationPopUp"
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  tname: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1rem",
    color: " rgb(61, 66, 74)",
    padding: ".5rem 1rem",
    marginLeft: "2%",
  },
  imgP: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1rem",
    color: " rgb(61, 66, 74)",
    padding: ".5rem 1rem",
    marginLeft: "2%",
    display: "flex",
    alignItems: "center",
  },
  link: {
    display: "flex",
    alignItems: "center",
  }
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[600],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function CustomizedDialogs({ itemList, deleteItem, loadData }) {
  let navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [alert, setAlert] = React.useState({
    showAlert: false,
    message: "",
    severity: "success",
  });

  const approveBilling = async (id) => {
    try {
      const res = await axios.put("/billing/admin-approval-status/", {
        id,
        status : "verified",
      });
      if (res.status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Status changed!",
        });
        loadData();
        setTimeout(() => {
          handleClose();
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 2000);
      }
      navigate('/billing-history');
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(() => {
          handleClose();
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 2000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Something went wrong!",
        });

        setTimeout(() => {
          handleClose();
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 2000);
      }
    }
  };

  const rejectBilling = async (id) => {
    try {
      const res = await axios.put("/billing/admin-reject-status/", {
        id,
        status : "rejected",
      });
      if (res.status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Status changed!",
        });
        loadData();
        setTimeout(() => {
          handleClose();
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 2000);
      }
      navigate('/billing-history');
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(() => {
          handleClose();
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 2000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Something went wrong!",
        });

        setTimeout(() => {
          handleClose();
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 2000);
      }
    }
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        View More
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={"lg"}
        maxHeight={"xl"}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Billing Details
        </DialogTitle>

        <Grid
          style={{
            height: "23rem",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "flexStart",
          }}
        >
          <p className={classes.tname}> Hotel Name   : {itemList?.hotelName}</p>
          <p className={classes.tname}> Amount         : {itemList?.paidAmount}</p>
          <p className={classes.tname}> Submitted Date : {itemList?.date ? new Date(itemList?.date).toLocaleString() : "-"}</p>
          <p className={classes.tname}> Ref note       : {itemList?.reference}</p>
          <p className={classes.tname}> Contact Number : {itemList?.contactNumber}</p>
          <p className={classes.tname}> Payment Slip Image <br />
            <br />
            <Grid className={classes.link} >
            <img
              src={itemList?.attachment}
              style={{ maxHeight: "200px", marginRight:"1rem"  }}
              alt="first screenshot"
            />
            <a href={itemList?.attachment} target="_blank" rel="noreferrer" >Click to Open!</a>
            </Grid>
          </p>
 
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          {alert.showAlert && (
            <Alert
              open={alert.showAlert}
              severity={alert.severity}
              sx={{ width: "100%" }}
            >
              {alert.message}
            </Alert>
          )}
        </Grid>

        {itemList?.status?.value === "verified" ? 
          <></>
          :  
          <Grid
            container
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "green",
                color: "white",
                marginRight: "1rem",
                marginLeft: "1rem",
              }}
              onClick={() => approveBilling(itemList?._id)}
            >
              Approve
            </Button>

            <ConfirmPopup itemList={itemList} rejectBilling={rejectBilling} />
          </Grid>
        }
       
      </Dialog>
    </div>
  );
}
