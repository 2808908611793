import React, { Fragment } from "react";
import { TableCell, Button } from "@material-ui/core";
import ReusableTable from "../../../components/layouts/ReusableTable";
import SimpleDialog from "../../../components/layouts/SimpleDialog";
import Accommodationpopup from "../Accommodation/Accommodationpopup";

const headers = [
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Bed",
    value: "bed",
  },
  {
    text: "Size",
    value: "size",
  },
  // {
  //   text: "Facility",
  //   value: "facility",
  // },
  {
    text: "Price",
    value: "price",
  },
  {
    text: "Discount",
    value: "discount",
  },
  {
    text: "More Details",
    value: "itemList",
  },
  {
    text: "Update",
    value: "update",
  },
  {
    text: "Delete",
    value: "delete",
  },
];
export default function TestimonialList(props) {
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleClose = (value) => {
    setOpen(false);
  };

  const TableB = ({
    item,
    changeStatus,
    classes,
    onUpdate,
    resetPassword,
    deleteItem,
  }) => {
    return (
      <Fragment>
        <TableCell key="name">{item.name}</TableCell>
        <TableCell key="bed">{item.bed}</TableCell>
        <TableCell key="size">{item.size}</TableCell>
        {/* <TableCell key="facility">{item.facility}</TableCell> */}
        <TableCell key="price">{item.price}</TableCell>
        <TableCell key="discount">{item.discount}</TableCell>
        <TableCell key="itemList">
          <Accommodationpopup itemList={item} changeStatus={changeStatus} classes={classes} />
        </TableCell>

        <TableCell key="update">
          <Button
            variant="contained"
            className={classes.yellow}
            onClick={() => onUpdate(item._id, item)}
          >
            update
          </Button>
        </TableCell>
        <TableCell key="delete">
          <Button
            variant="contained"
            className={classes.red}
            onClick={() => deleteItem(item._id)}
          >
            Delete
          </Button>
        </TableCell>
        <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title="View Accommodations"
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl="/accommodation/"
      statusChangeUrl="/item/status/"
      updatePath="/update-accommodation/"
      deleteItemPath="/accommodation"
      resetPasswordPath="/auth/forgot-password"
      {...props}
    />
  );
}
