import * as Yup from "yup";
const phoneRegExp = /^(?:7|0|(?:\+94))[0-9]{9,11}$/;

export const uptateValidationSchema = Yup.object().shape({
  userName: Yup.string()
    .min(3, "Too Short!")
    .max(30, "Too Long!")
    .required("First name is required"),
  role: Yup.string(),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required!"),
  email: Yup.string()
    .required("Email is required!")
    .trim()
    .matches(
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    )
    .max(320, "Must be less than 320 digits"),
});
