import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Box,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import axios from "libs/axios";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../../utils/styles/styles";
import Button from "../../../components/UI/ReusableButton";
import CircularProgress from "../../../components/UI/CircularProgress";
import storage from "../../../firebase/firebase";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

import { Formik, Form, Field } from "formik";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { TextField } from "formik-material-ui";

const UpdateGallery = (props) => {
  const classes = useStyles();
  const { state } = useLocation();
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#1a90ff",
    },
  }))(LinearProgress);

  const [initialValues, setInitialValues] = useState({
    galleryimage1:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    galleryimage2:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    galleryimage3:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    galleryimage4:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    galleryimage5:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    galleryimage6:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    galleryimage7:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    galleryimage8:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    galleryimage9:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    galleryimage10:
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c",
    videolink: "",
  });
  const [defaultImageUpload, setDefaultImageUpload] = useState(false);
  const [percent, setPercent] = useState(0);
  const [progbar, setprogbar] = useState(false);
  const [callingBackend, setCallingBackend] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e, { resetForm }) => {
    try {
      console.log("update");
      setCallingBackend(true);
      await axios.put(`/item/gallery`, {
        galleryimage1: e.galleryimage1,
        galleryimage2: e.galleryimage2,
        galleryimage3: e.galleryimage3,
        galleryimage4: e.galleryimage4,
        galleryimage5: e.galleryimage5,
        galleryimage6: e.galleryimage6,
        galleryimage7: e.galleryimage7,
        galleryimage8: e.galleryimage8,
        galleryimage9: e.galleryimage9,
        galleryimage10: e.galleryimage10,
        videolink: e.videolink,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Gallery Create successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Gallery Create successfully!",
          }),
        5000
      );
    } catch (error) {
      if (error.response.data === "Gallery already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Gallery already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Gallery already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Gallery already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Gallery already exists!",
            }),
          5000
        );
      }
    } finally {
      setCallingBackend(false);
    }
  };

  const get = async (id) => {
    try {
      const { data } = await axios.get(`/item/update-data`);
      setInitialValues({
        galleryimage1: data.gallery.galleryimage1,
        galleryimage2: data.gallery.galleryimage2,
        galleryimage3: data.gallery.galleryimage3,
        galleryimage4: data.gallery.galleryimage4,
        galleryimage5: data.gallery.galleryimage5,
        galleryimage6: data.gallery.galleryimage6,
        galleryimage7: data.gallery.galleryimage7,
        galleryimage8: data.gallery.galleryimage8,
        galleryimage9: data.gallery.galleryimage9,
        galleryimage10: data.gallery.galleryimage10,
        videolink: data.gallery.videolink,
      });
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Data loading failed!",
          }),
        5000
      );
    }
  };

  const handleImageAsFile1 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue("galleryimage1", url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };
  const handleImageAsFile2 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue("galleryimage2", url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };
  const handleImageAsFile3 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue("galleryimage3", url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };
  const handleImageAsFile4 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue("galleryimage4", url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };
  const handleImageAsFile5 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue("galleryimage5", url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };
  const handleImageAsFile6 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue("galleryimage6", url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };
  const handleImageAsFile7 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue("galleryimage7", url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };
  const handleImageAsFile8 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue("galleryimage8", url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };
  const handleImageAsFile9 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue("galleryimage9", url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };
  const handleImageAsFile10 = async (e, setFieldValue, type, values) => {
    try {
      setDefaultImageUpload(true);
      const image = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${image.name}${image.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
          if (percent === 100) {
            setTimeout(() => {
              setprogbar(false);
            }, 3000);
          } else if (1 < percent < 99) {
            setprogbar(true);
          } else {
            setprogbar(false);
          }
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue("galleryimage10", url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };

  const removeImage1 = (setFieldValue) => {
    setFieldValue(
      "galleryimage1",
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
    );
  };
  const removeImage2 = (setFieldValue) => {
    setFieldValue(
      "galleryimage2",
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
    );
  };
  const removeImage3 = (setFieldValue) => {
    setFieldValue(
      "galleryimage3",
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
    );
  };
  const removeImage4 = (setFieldValue) => {
    setFieldValue(
      "galleryimage4",
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
    );
  };
  const removeImage5 = (setFieldValue) => {
    setFieldValue(
      "galleryimage5",
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
    );
  };
  const removeImage6 = (setFieldValue) => {
    setFieldValue(
      "galleryimage6",
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
    );
  };
  const removeImage7 = (setFieldValue) => {
    setFieldValue(
      "galleryimage7",
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
    );
  };
  const removeImage8 = (setFieldValue) => {
    setFieldValue(
      "galleryimage8",
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
    );
  };
  const removeImage9 = (setFieldValue) => {
    setFieldValue(
      "galleryimage9",
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
    );
  };
  const removeImage10 = (setFieldValue) => {
    setFieldValue(
      "galleryimage10",
      "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c"
    );
  };

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (state && state?.id) {
      get();
    }
    setTimeout(() => setIsLoading(false), 1000);
    // eslint-disable-next-line
  }, [state?.id]);

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Card variant="outlined">
                  <CardHeader title="Gallery" />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo1">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo1"
                                  name="upload-photo1"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile1(
                                      e,
                                      setFieldValue,
                                      "galleryimage1",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 1 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.galleryimage1 && (
                                <img
                                  src={values.galleryimage1}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.galleryimage1 !== "" &&
                                values.galleryimage1 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage1(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo2">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo2"
                                  name="upload-photo2"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile2(
                                      e,
                                      setFieldValue,
                                      "galleryimage2",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 2 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.galleryimage2 && (
                                <img
                                  src={values.galleryimage2}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.galleryimage2 !== "" &&
                                values.galleryimage2 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage2(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo3">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo3"
                                  name="upload-photo3"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile3(
                                      e,
                                      setFieldValue,
                                      "galleryimage3",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 3 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.galleryimage3 && (
                                <img
                                  src={values.galleryimage3}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.galleryimage3 !== "" &&
                                values.galleryimage3 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage3(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo4">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo4"
                                  name="upload-photo4"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile4(
                                      e,
                                      setFieldValue,
                                      "galleryimage4",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 4 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.galleryimage4 && (
                                <img
                                  src={values.galleryimage4}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.galleryimage4 !== "" &&
                                values.galleryimage4 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage4(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo5">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo5"
                                  name="upload-photo5"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile5(
                                      e,
                                      setFieldValue,
                                      "galleryimage5",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 5 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.galleryimage5 && (
                                <img
                                  src={values.galleryimage5}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.galleryimage5 !== "" &&
                                values.galleryimage5 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage5(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo6">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo6"
                                  name="upload-photo6"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile6(
                                      e,
                                      setFieldValue,
                                      "galleryimage6",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 6 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.galleryimage6 && (
                                <img
                                  src={values.galleryimage6}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.galleryimage6 !== "" &&
                                values.galleryimage6 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage6(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo7">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo7"
                                  name="upload-photo7"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile7(
                                      e,
                                      setFieldValue,
                                      "galleryimage7",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 7 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.galleryimage7 && (
                                <img
                                  src={values.galleryimage7}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.galleryimage7 !== "" &&
                                values.galleryimage7 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage7(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo8">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo8"
                                  name="upload-photo8"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile8(
                                      e,
                                      setFieldValue,
                                      "galleryimage8",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 8 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.galleryimage8 && (
                                <img
                                  src={values.galleryimage8}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.galleryimage8 !== "" &&
                                values.galleryimage8 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage8(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo9">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo9"
                                  name="upload-photo9"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile9(
                                      e,
                                      setFieldValue,
                                      "galleryimage9",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 9 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.galleryimage9 && (
                                <img
                                  src={values.galleryimage9}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.galleryimage9 !== "" &&
                                values.galleryimage9 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) => removeImage9(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {defaultImageUpload ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor="upload-photo10">
                                <input
                                  style={{
                                    display: "none",
                                    marginTop: "1rem",
                                  }}
                                  id="upload-photo10"
                                  name="upload-photo10"
                                  type="file"
                                  accept="image/jpeg,image/png,image/webp"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile10(
                                      e,
                                      setFieldValue,
                                      "galleryimage10",
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color="secondary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  Image 10 upload
                                </Button>
                              </label>
                            </div>
                            <div>
                              {progbar && (
                                <BorderLinearProgress
                                  variant="determinate"
                                  style={{ width: "100%" }}
                                  value={percent}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              {values.galleryimage10 && (
                                <img
                                  src={values.galleryimage10}
                                  alt="images"
                                  style={{
                                    maxHeight: "200px",
                                    marginTop: "1.5rem",
                                  }}
                                />
                              )}
                              {values.galleryimage10 !== "" &&
                                values.galleryimage10 !==
                                "https://firebasestorage.googleapis.com/v0/b/mr-traveler.appspot.com/o/files%2Fdownload%20(4).pngSun%20Aug%2027%202023%2018%3A50%3A54%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a45ded0b-5b34-448a-b516-e9c054374f7c" ? (
                                <Box ml={2} display="inline">
                                  <Button
                                    style={{ marginTop: "1.5rem" }}
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(e) =>
                                      removeImage10(setFieldValue)
                                    }
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Field
                        name="videolink"
                        label="Video link"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        required
                        multiline
                      />
                    </Grid>
                  </CardContent>

                  <CardActions>
                    {callingBackend ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        type="submit"
                        disabled={!dirty}
                      >
                        Save
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {alert.showAlert && (
        <Grid item md={12}>
          <Alert
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, showAlert: false })}
          >
            {alert.message}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default UpdateGallery;
