import React, { Fragment } from "react";
import { TableCell, Button } from "@material-ui/core";
import ReusableTable from "../../../components/layouts/ReusableTable";
import SimpleDialog from "../../../components/layouts/SimpleDialog";

const headers = [
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Country",
    value: "country",
  },
  {
    text: "Heading",
    value: "heading",
  },
  {
    text: "Description",
    value: "description",
  },
  {
    text: "Change Status",
    value: "status",
  },
  {
    text: "Delete",
    value: "delete",
  },
];
export default function TestimonialList(props) {
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleClose = (value) => {
    setOpen(false);
  };

    const TableB = ({
      item,
      changeStatus,
      classes,
      onUpdate,
      resetPassword,
      deleteItem,
    }) => {
      return (
        <Fragment>
          <TableCell key="name">{item.name}</TableCell>
          <TableCell key="country">{item.country}</TableCell>
          <TableCell key="heading">{item.heading}</TableCell>
          <TableCell key="description">{item.description}</TableCell>
          <TableCell key="update">
            <Button
              variant="contained"
              className={classes.yellow}
              onClick={() => onUpdate(item._id, item)}
            >
              update
            </Button>
          </TableCell>
          <TableCell key="delete">
            <Button
              variant="contained"
              className={classes.red}
              onClick={() => deleteItem(item._id)}
            >
              Delete
            </Button>
          </TableCell>
          <SimpleDialog
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
          />
        </Fragment>
      );
    };
  return (
    <ReusableTable
      title="View Testimonials"
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl="/testimonial/"
      statusChangeUrl="/item/status/"
      updatePath="/update-testimonial/"
      deleteItemPath="/testimonial"
      resetPasswordPath="/auth/forgot-password"
      {...props}
    />
  );
}
